import React from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Divider from "@material-ui/core/Divider";
import { toast } from "react-toastify";
import api from "../network/api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    paper: {
      marginRight: theme.spacing(2),
    },
  })
);

interface Props {
  customerId?: number;
  cancelled?: boolean;
}

function SubscriptionActions({ customerId, cancelled }: Props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const deleteCustomer = async () => {
    if (
      window.confirm(
        "Are you sure you want to cancel this customer's subscription?"
      )
    ) {
      try {
        await api.delete(`/customers/${customerId}`);
        toast.success("Cancelled customer's subscription successfully");
        navigate("/", { replace: true });
      } catch (error) {
        toast.error("Error cancelling customer's subscription");
      }
    }
  };

  const editCustomer = async () => {
    navigate(`/${customerId}/edit`);
  };

  return (
    <div className={classes.root}>
      <div>
        <Button
          style={{
            backgroundColor: "rgba(255,255,255,0.2)",
            color: "#fff",
            borderRadius: "8px",
          }}
          className="flex justify-between"
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <span>ACTIONS</span>
          <KeyboardArrowDown />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          className="mt-2 mr-3 bg-gray-900 rounded-md text-white"
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    className="bg-gray-900 text-white  border border-gray-700"
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={(e) => {
                        handleClose(e);
                        editCustomer();
                      }}
                      className="hover:opacity-70"
                    >
                      <div
                        className="row"
                        style={{ paddingTop: "10px", paddingBottom: "5px" }}
                      >
                        <Edit />
                        <div className="padding">Edit Customer</div>
                      </div>
                    </MenuItem>
                    <Divider variant="middle" />
                    {!cancelled && (
                      <MenuItem
                        onClick={(e) => {
                          handleClose(e);
                          deleteCustomer();
                        }}
                        className="hover:opacity-70"
                      >
                        <div
                          className="row"
                          style={{
                            color: "#f00",
                            paddingTop: "10px",
                            paddingBottom: "5px",
                          }}
                        >
                          <Delete />
                          <div className="padding">Cancel Subscription</div>
                        </div>
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

export default SubscriptionActions;
