import React, { useState } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { Create } from "@material-ui/icons";
import { Influencer } from "../../@types";
import { BeatLoader } from "react-spinners";
import toMessage from "../../assets/images/to-message.svg";
import api from "../../network/api";
import MoreHorizontal from "../SVGIcons/MoreHorizontal";


const useStyles = makeStyles((theme) => ({
  noteHeading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  note: {
    minHeight: "12vh",
    background: "#fff",
    borderRadius: "10px",
    padding: 0,
    margin: 0,
  },
  icon: {
    color: "#000",
    background: "#fff",
    marginRight: "1rem",
    borderRadius: "5px",
    height: "2.4rem",
    "&:hover": {
      color: "#000",
      background: "#fff",
    },
  },
  templateBottom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "1rem",
  },
  sendBtn: {
    border: "none",
    background: "#ffcc00",
    fontWeight: "bold",
    padding: ".75rem 2rem",
    color: "#000",
    borderRadius: "10px",
    cursor: "pointer",
  },
  toImage: {
    position: "absolute",
    bottom: "-10px",
    right: 0,
    zIndex: 999,
  },
  wrapper: {
    position: "relative",
  },
}));

interface Props {
  isOld?: boolean;
  subject?: string;
  showSubjectLine?: boolean;
  message: string;
  user?: Influencer;
  loading?: boolean;
  setMessage?: (m: string) => void;
  setSubject?: (s: string) => void;
  sendMail?: () => Promise<void>;
}

const SendMailForm: React.FC<Props> = ({
  isOld,
  subject,
  message,
  user,
  setMessage,
  setSubject,
  loading,
  sendMail,
  showSubjectLine,
}) => {
  const [selectedTemplate, setSelectedTemplate] = React.useState(0);
  const classes = useStyles();
  const [templates, setTemplates] = useState([]);
  const [hideAllTemplates, setHideAllTemplates] = useState(true);

  React.useEffect(() => {
    const getTemplates = async () => {
      try {
        const { data: templates } = await api.get("/templates", {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        });

        setTemplates(templates);
      } catch (error) {
        console.log(error);
      }
    };

    getTemplates();
  }, []);

  return (
    <div className={classes.wrapper}>
      <input
        placeholder="Subject line"
        style={{
          display: showSubjectLine ? "flex" : "none",
          paddingInline: "20px",
          width: "100%",
          paddingBlock: "10px",
          marginTop: "20px",
          borderRadius: "10px",
          backgroundColor: "white",
          color: "#000",
          fontWeight: "bold",
        }}
        disabled={isOld || loading}
        value={subject}
        onChange={(e) => {
          setSubject && setSubject(e.target.value);
        }}
      />
      <div
        style={{
          borderRadius: "10px",
          marginTop: ".5rem",
          width: "100%",
          color: "black",
          display: "flex",
          padding: "1.5rem",
          flexDirection: "column",
          backgroundColor: "white",
          minHeight: "10vh",
        }}
      >
        {!isOld && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ flexDirection: "row", display: "flex" }}>
              {templates
                .slice(0, hideAllTemplates ? 3 : templates.length)
                .map((template, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setSelectedTemplate(index);
                      setMessage && setMessage(template["template"]);
                    }}
                    style={{
                      borderRadius: "10px",
                      backgroundColor:
                        index === selectedTemplate ? "#ffcc00" : "#E9F4F9",
                      fontWeight: "bold",
                      cursor: "pointer",
                      marginRight: "10px",
                      paddingInline: "1rem",
                      paddingBlock: "0.7rem",
                    }}
                  >
                    {template["templateName"]}
                  </div>
                ))}
            </div>

            <div
              onClick={() => setHideAllTemplates(!hideAllTemplates)}
              style={{
                height: "20px",
                width: "20px",
                display : templates.length < 3 ? 'none' : 'block',
                marginLeft: "20px",
                cursor: "pointer",
              }}
            >
              {hideAllTemplates ? <MoreHorizontal /> : "x"}
            </div>
          </div>
        )}
        <textarea
          style={{ marginTop: "2rem", border: "none" }}
          placeholder="Message body"
          disabled={isOld || loading}
          value={message}
          onChange={(e) => {
            setMessage && setMessage(e.target.value);
          }}
        />
      </div>
      {!isOld ? (
        <div className={classes.templateBottom}>
          <IconButton className={classes.icon} disabled={isOld || loading}>
            <Create />
          </IconButton>
          <button
            className={classes.sendBtn}
            disabled={isOld || loading}
            onClick={sendMail}
          >
            {loading ? <BeatLoader color="#000" /> : "Send"}
          </button>
        </div>
      ) : (
        <img className={classes.toImage} alt="to-img" src={toMessage} />
      )}
    </div>
  );
};

export default SendMailForm;
