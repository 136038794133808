import { AnyAction } from 'redux';
import { User } from '../../@types';

export interface AuthState {
	authenticated: boolean;
	user?: User;
}

export const AUTH_ACTIONS = {
	LOGIN_USER: 'LOGIN_USER',
	LOGOUT_USER: 'LOGOUT_USER',
};

export const initialState: AuthState = {
	authenticated: false,
};

export const loginUser = (user: User) => {
	return {
		type: AUTH_ACTIONS.LOGIN_USER,
		payload: user,
	};
};

export const logoutUser = () => {
	return {
		type: AUTH_ACTIONS.LOGOUT_USER,
	};
};

export default function authReducer(state = initialState, action: AnyAction): AuthState {
	switch (action.type) {
		case AUTH_ACTIONS.LOGIN_USER:
			return {
				...state,
				authenticated: true,
				user: action.payload,
			};
		case AUTH_ACTIONS.LOGOUT_USER:
			return { ...state, authenticated: false, user: undefined };

		default:
			return state;
	}
}
