import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Customer, Influencer } from "../../@types";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import styled from "styled-components";
import UsersIcon from "../SVGIcons/UsersIcon";
import api from "../../network/api";

interface HeadCell {
  disablePadding: boolean;
  id: keyof Customer;
  label: string;
  numeric: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const headCells: HeadCell[] = [
  { id: "name", numeric: false, disablePadding: false, label: "Name" },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  {
    id: "subscriptionType",
    numeric: false,
    disablePadding: false,
    label: "Plan",
  },
  {
    id: "subscriptionStart",
    numeric: false,
    disablePadding: false,
    label: "Subscription Start",
  },
  {
    id: "daysUntilNextBox",
    numeric: false,
    disablePadding: false,
    label: "Days Until Next Box",
  },
  { id: "received", numeric: false, disablePadding: false, label: "Received" },
];

// function InfluencerTableHead() {
//   const classes = useStyles();

//   return (
//     <TableHead>
//       <TableRow className={classes.tableRow}>
//         {headCells.map((headCell) => (
//           <TableCell
//             key={headCell.id}
//             align={headCell.numeric ? "right" : "left"}
//             padding={headCell.disablePadding ? "none" : "normal"}
//             className={classes.headerCell}
//           >
//             <TableSortLabel className={classes.cell}>
//               {headCell.label}
//             </TableSortLabel>
//           </TableCell>
//         ))}
//       </TableRow>
//     </TableHead>
//   );
// }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      // marginTop: "2rem",
      // background: "#000",
    },
    paper: {
      width: "100%",
      // marginBottom: theme.spacing(2),
      background: "var(--charcoal)",
      boxShadow: "none",
    },
    table: {
      width: "100%",
      // background: "#000",
      color: "#fff",
      borderSpacing: "0 0px",
      borderCollapse: "separate",
      borderRadius: "15px",
      overflow: "hidden",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    tableRow: {
      // background: "#E9F4F9",
      transition: "all 0.2s all",
      opacity: "0.95",
      "&:hover": {
        cursor: "pointer",
        // background: "#E9F4F9",
        opacity: "1",
      },
    },
    cell: {
      color: "#fff",
      fontWeight: "bold",
    },
    headerCell: {
      color: "#000",
      fontWeight: "bold",
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

interface Props {
  influencers: Influencer[];
  loading?: boolean;
  hideHeader?: boolean;
  emailcount?: number;
  emailReplied?: boolean;
}

const EmailTable: React.FC<Props> = ({
  influencers,
  loading,
  hideHeader,
  emailcount,
  emailReplied,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  // let [filteredFnfluencers, setFilteredInfluencers] = useState<Influencer[]>([])

  const onUserSelect = (customerId: number, email: string) => {
    api.post("/influencer/read", {
      email: email,
      is_read: true,
    });
    navigate(`/influencers/${email}`);
  };

  // useEffect(() => {
  //   setInfluencers(originalInfluencers)
  // }, []);

  return (
    <div className={classes.root}>
      <div
        className="text-xl  flex items-center px-4 mb-5"
        // style={{
        //   display:
        //     influencers.filter(
        //       (influencer) =>
        //         influencer.emails_sent === emailcount && !influencer.is_ordered
        //     ).length > 0
        //       ? "flex"
        //       : "none",
        // }}
      >
        <div
          className="h-5 w-5 mr-2.5 transform -translate-y-[2px]"
          // style={{
          //   height: "20px",
          //   width: "20px",
          //   marginRight: "10px",
          //   transform: "translateY(-2px)",
          // }}
        >
          <UsersIcon />
        </div>
        {
          influencers.filter(
            (influencer) =>
              influencer.emails_sent === emailcount && !influencer.is_ordered
          ).length
        }
        {influencers.filter(
          (influencer) =>
            influencer.emails_sent === emailcount && !influencer.is_ordered
        ).length === 1
          ? " influencer"
          : " influencers"}
      </div>
      <Paper className={classes.paper}>
        <TableContainer>
          {loading ? (
            <LoadDiv>
              <BeatLoader color="#ffcc00" />
            </LoadDiv>
          ) : (
            <>
              {influencers.filter(
                (influencer) =>
                  influencer.emails_sent === emailcount &&
                  !influencer.is_ordered
              ).length === 0 ? (
                <div className="flex justify-center text-xl text-white h-[39vh] items-center">
                  No Influencer on this list
                </div>
              ) : (
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size="medium"
                  aria-label="enhanced table"
                >
                  <TableBody>
                    {influencers
                      .filter(
                        (influencer) =>
                          influencer.emails_sent === emailcount &&
                          !influencer.is_ordered
                      )
                      .map((influencer) => (
                        <TableRow
                          key={influencer.id}
                          aria-checked={true}
                          onClick={() =>
                            onUserSelect(influencer.id, influencer.email)
                          }
                          selected={false}
                          className={classes.tableRow}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.cell}
                          >
                            {`${influencer.first_name} ${influencer.last_name}`}
                          </TableCell>
                          <TableCell align="left" className={classes.cell}>
                            {influencer.email}
                          </TableCell>

                          <TableCell align="left" className={classes.cell}>
                            {influencer.created_at
                              ? format(
                                  new Date(influencer.created_at),
                                  "dd-MM-yyyy"
                                )
                              : "-----"}
                          </TableCell>

                          <TableCell align="left" className={classes.cell}>
                            <div
                              style={{
                                paddingInline: "10px",
                                paddingBlock: "5px",
                                backgroundColor: "#ffcc00",

                                borderRadius: "10px",
                                display: !influencer.is_read ? "flex" : "none",
                                width: "50px",
                                justifyContent: "center",
                              }}
                            >
                              New
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              )}
            </>
          )}
        </TableContainer>
      </Paper>
    </div>
  );
};

const LoadDiv = styled.div`
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default EmailTable;
