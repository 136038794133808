import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { toast } from "react-toastify";
import React, { useState, FC } from "react";
import { Image } from "semantic-ui-react";
import { capitalCase } from "change-case";
import { useNavigate, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import "../css/sidebar.css";
import "react-pro-sidebar/dist/css/styles.css";

// // misc
import K from "../../../constants";
import lookup from "../../../assets/images/lookup.svg";
import logo from "../../../assets/images/mochi-logo.png";
import customers from "../../../assets/images/customers.svg";
import confirmed from "../../../assets/images/confirmed.svg";
import templates from "../../../assets/images/templates.svg";
import influencers from "../../../assets/images/influencers.svg";
// store
import { RootState } from "../../../store/root";
import { logoutUser } from "../../../store/modules/auth.reducer";
import { setActiveSidebar } from "../../../store/modules/misc.reducer";

// svg
import MenuIcon from "../../SVGIcons/MenuIcon";
import LogoutIcon from "../../SVGIcons/LogoutIcon";

interface AppProps {
  wrapperClass?: string;
}

const SideBar: FC<AppProps> = ({ wrapperClass }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [menuCollapse, setMenuCollapse] = useState(false);
  const misc = useSelector((state: RootState) => state.misc);

  const sidebarItems: { sidebarItem: string; subItems: string[] }[] = [
    { sidebarItem: "customers", subItems: ["live", "paused", "abandoned"] },
    { sidebarItem: "influencers", subItems: [] },
    { sidebarItem: "templates", subItems: [] },
    { sidebarItem: "product-qr", subItems: [] },
  ];

  const logout = () => {
    window.localStorage.removeItem(K.LWM_ADMIN_TOKEN_KEY);
    dispatch(logoutUser());
    toast.success("Logged out successfully");
  };

  const setSidebar = (sidebar: string) => {
    dispatch(setActiveSidebar(sidebar));
  };

  const renderIcon = (sidebar: string) => {
    switch (sidebar) {
      case "customers":
        return customers;
      case "abandoned":
        return customers;
      case "cancelled":
        return customers;
      case "confirmed":
        return confirmed;
      case "lookup":
        return lookup;
      case "influencers":
        return influencers;
      default:
        return templates;
    }
  };

  return (
    <aside className={`h-screen ${wrapperClass}`}>
      <ProSidebar collapsed={menuCollapse} className="AppSidebar">
        <SidebarHeader className="bg-[color:var(--charcoal)]">
          <div
            className={`flex items-center justify-center transition-all duration-200 ${
              menuCollapse ? "p-2" : "px-16 py-6"
            }`}
          >
            <Image className={`w-full h-auto rounded-full`} src={logo} />
          </div>
        </SidebarHeader>
        <SidebarContent className="bg-[color:var(--charcoal)]">
          <Menu>
            {sidebarItems.map((item, index) => (
              <MenuItem
                key={index}
                className={` hover:cursor-pointer h-[60px] hover:bg-[color:var(--dark-charcoal)] transition-all duration-100 ease-in-out relative my-3 ml-4 rounded-l-full ${
                  misc.activeSidebar === item.sidebarItem
                    ? "active sidebar-item bg-[color:var(--dark-charcoal)]"
                    : "inactive sidebar-item"
                }`}
                active={misc.activeSidebar === item.sidebarItem}
                onClick={() => {
                  setSidebar(item.sidebarItem);
                  navigate(
                    `/${
                      item.sidebarItem === "customers" ? "" : item.sidebarItem
                    }`
                  );
                }}
              >
                {misc.activeSidebar === item.sidebarItem && (
                  <div className="absolute bottom-full right-0 left-0 h-3 bg-[color:var(--dark-charcoal)] before:absolute before:inset-0 before:bg-[color:var(--charcoal)] before:rounded-br-lg"></div>
                )}
                <NavLink
                  end
                  className="flex items-center gap-1"
                  to={`/${
                    item.sidebarItem === "customers" ? "" : item.sidebarItem
                  }`}
                >
                  <img
                    src={renderIcon(item.sidebarItem)}
                    alt="item"
                    className="w-6 h-6"
                  />
                  <span
                    className={`transition-all duration-200 pl-2 ease-out opacity-[${
                      menuCollapse ? 0 : 1
                    }]`}
                  >
                    {capitalCase(item.sidebarItem)}
                  </span>
                </NavLink>
                {misc.activeSidebar === item.sidebarItem && (
                  <div className="absolute top-full right-0 left-0 h-3 bg-[color:var(--dark-charcoal)] before:absolute before:inset-0 before:bg-[color:var(--charcoal)] before:rounded-tr-lg"></div>
                )}
              </MenuItem>
            ))}
          </Menu>
        </SidebarContent>

        <SidebarFooter className="bg-[color:var(--charcoal)]">
          <button
            className="flex justify-center w-fit mx-auto p-2 mt-2"
            onClick={() => setMenuCollapse(!menuCollapse)}
          >
            <div className="cursor-pointer">
              <MenuIcon />
            </div>
          </button>
          <Menu iconShape="square" className="flex items-center justify-center">
            <button
              onClick={logout}
              className="text-black bg-[#ffcc00] mx-4 py-2 px-3 rounded-[4px] w-inherit flex items-center justify-center"
            >
              {menuCollapse ? <LogoutIcon /> : "Logout"}
            </button>
          </Menu>
        </SidebarFooter>
      </ProSidebar>
    </aside>
  );
};

export default SideBar;
