/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import * as Yup from "yup";
import { format } from "date-fns";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Button, Switch } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import "./new.css";
import api from "../../../network/api";
import { RootState } from "../../../store/root";
import BackNavigator from "../../../components/shared/buttons/BackNavigator/BackNavigator";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // syncIcon: {
    //   height: 20,
    //   width: 20,
    // },
    typography: {
      fontWeight: "bold",
      color: "#eaeaea",
      justifyContent: "flex-end",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    typographyLight: {
      color: "grey",
      fontWeight: "bold",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    tablePadding: {
      paddingLeft: "100px",
      paddingRight: "100px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    bold: {
      fontWeight: "bold",
    },
    // root: {
    //   width: "100%",
    //   backgroundColor: "#000",
    //   color: "#fff",
    // },
    chip: {
      borderRadius: "5px",
      fontWeight: "bold",
      marginLeft: "12px",
      marginBottom: "10px",
      height: "20px",
      color: "green",
      backgroundColor: "#90ee90",
    },
    section1: {
      margin: theme.spacing(3, 2),
    },
    section2: {
      margin: theme.spacing(2),
    },
    section3: {
      margin: theme.spacing(3, 1, 1),
    },
  })
);

const CreateCustomer: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = React.useState(false);

  const { values, handleChange, handleSubmit, setFieldValue, errors } =
    useFormik<{
      name: string;
      email: string;
      childName: string;
      childBirthday: Date;
      customerId: string;
      couponApplied: string;
      instagramAccount: string;
      phone: string;
      shippingAddress: string;
      influencer: boolean;
      subscriptionType: string;
      subscriptionStart?: Date;
    }>({
      initialValues: {
        name: "",
        email: "",
        childName: "",
        childBirthday: new Date(),
        customerId: "",
        couponApplied: "",
        instagramAccount: "",
        phone: "",
        shippingAddress: "",
        influencer: false,
        subscriptionType: "",
      },
      onSubmit: async (values) => {
        try {
          setLoading(true);
          const data: any = {
            ...values,
            childBirthday: format(values.childBirthday, "yyyy-MM-dd"),
          };
          if (values.subscriptionStart) {
            data["subscriptionStart"] = format(
              values.subscriptionStart,
              "yyyy-MM-dd"
            );
          }
          await api.post(`/customers/new`, data, {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
          });
          toast.success("Customer added successfully");
          setLoading(false);
          navigate("/");
        } catch (error) {
          toast.error("Unable to add customer");
          setLoading(false);
        }
      },
      validationSchema: Yup.object({
        name: Yup.string().required(),
        email: Yup.string().email().required(),
        childName: Yup.string().required(),
        childBirthday: Yup.string().required(),
        customerId: Yup.string().optional(),
        couponApplied: Yup.string().optional(),
        instagramAccount: Yup.string().optional(),
        phone: Yup.string().required(),
        shippingAddress: Yup.string().required(),
      }),
    });

  React.useEffect(() => {
    if (!auth.authenticated) {
      navigate("/login");
    }
  }, [auth]);

  return (
    <main className="main bg-[color:var(--charcoal)] w-full max-w-7xl p-6 pt-2 my-4 rounded-md mx-auto">
      <div className={classes.section1}>
        <Grid container>
          <div className="row">
            <Grid item xs>
              <div className="row">
                <BackNavigator />
                <div style={{ marginLeft: "12px" }}>
                  <Typography
                    className={classes.typography}
                    gutterBottom
                    variant="body1"
                  >
                    SUBSCRIPTION
                  </Typography>
                </div>
              </div>
              <div className="row center my-6">
                <input
                  id="name"
                  name="name"
                  style={{ fontSize: "35px" }}
                  placeholder="Customer Name"
                  className={`create-input  text-[color:var(--dark-charcoal)] ${
                    errors.name ? "error-input" : ""
                  }`}
                  value={values.name}
                  onChange={handleChange}
                  type="text"
                  disabled={loading}
                />
              </div>
            </Grid>
            {/* <div className="float-right" style={{ alignItems: "flex-end" }}>
                <div className="column">
                  <Button
                    disableElevation={true}
                    variant="contained"
                    endIcon={<Add />}
                    style={{
                      marginLeft: "25px",
                      backgroundColor: "#ffc000",
                      color: "#000",
                      fontWeight: "bold",
                      borderRadius: "8px",
                    }}
                    onClick={() => handleSubmit()}
                    disabled={loading}
                  >
                    {loading ? <BeatLoader /> : "Create Customer"}
                  </Button>
                </div>
              </div> */}
          </div>
        </Grid>
      </div>
      <Divider variant="middle" />

      <br />
      <div className="padding">
        <Typography className={classes.bold} gutterBottom variant="h6">
          Customer Details
        </Typography>
      </div>
      <Divider variant="middle" />
      <br />
      <div className="px-5">
        <table className=" w-full max-w-[992]">
          <tbody>
            <tr>
              <td>
                <div className="flex items-center gap-x-4">
                  <Typography
                    className={`w-[200px] ${classes.typographyLight}`}
                    gutterBottom
                    variant="body1"
                  >
                    Email
                  </Typography>
                  <input
                    id="email"
                    name="email"
                    placeholder="Email"
                    className={`create-input  text-[color:var(--dark-charcoal)] ${
                      errors.email ? "error-input" : ""
                    }`}
                    value={values.email}
                    onChange={handleChange}
                    type="email"
                    disabled={loading}
                  />
                </div>
              </td>
              <td>
                <div className="flex items-center gap-x-4">
                  <Typography
                    className={`w-[200px] ${classes.typographyLight}`}
                    gutterBottom
                    variant="body1"
                  >
                    Phone Number
                  </Typography>
                  <input
                    id="phone"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    placeholder="Phone Number"
                    className={`create-input  text-[color:var(--dark-charcoal)] ${
                      errors.phone ? "error-input" : ""
                    }`}
                    type="text"
                    disabled={loading}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="flex items-center gap-x-4">
                  <Typography
                    className={`w-[200px] ${classes.typographyLight}`}
                    gutterBottom
                    variant="body1"
                  >
                    Child
                  </Typography>
                  <input
                    id="childName"
                    name="childName"
                    placeholder="Child's Name"
                    className={`create-input  text-[color:var(--dark-charcoal)] ${
                      errors.childName ? "error-input" : ""
                    }`}
                    value={values.childName}
                    onChange={handleChange}
                    type="text"
                    disabled={loading}
                  />
                </div>
              </td>
              <td rowSpan={2}>
                <div className="flex items-start gap-x-4">
                  <Typography
                    className={`w-[200px] pt-4 ${classes.typographyLight}`}
                    // gutterBottom
                    variant="body1"
                  >
                    Shipping Address
                  </Typography>
                  <textarea
                    id="shippingAddress"
                    name="shippingAddress"
                    value={values.shippingAddress}
                    onChange={handleChange}
                    placeholder="Shipping Address"
                    className={`create-input  text-[color:var(--dark-charcoal)] w-[230px] ${
                      errors.shippingAddress ? "error-input" : ""
                    }`}
                    rows={4}
                    disabled={loading}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="flex items-center gap-x-4">
                  <Typography
                    className={`w-[200px] ${classes.typographyLight}`}
                    gutterBottom
                    variant="body1"
                  >
                    Instagram Handle
                  </Typography>
                  <input
                    id="instagramAccount"
                    name="instagramAccount"
                    value={values.instagramAccount}
                    onChange={handleChange}
                    placeholder="Instagram Handle"
                    className={`create-input  text-[color:var(--dark-charcoal)] ${
                      errors.instagramAccount ? "error-input" : ""
                    }`}
                    type="text"
                    disabled={loading}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="flex items-center gap-x-4">
                  <Typography
                    className={`w-[200px] ${classes.typographyLight}`}
                    gutterBottom
                    variant="body1"
                  >
                    Child Birthday
                  </Typography>
                  <KeyboardDatePicker
                    value={values.childBirthday}
                    onChange={(date) => setFieldValue("childBirthday", date)}
                    format="dd/MM/yyyy"
                    variant="inline"
                    InputProps={{
                      style: { color: "white" },
                    }}
                    KeyboardButtonProps={{
                      style: { color: "white" },
                    }}
                    error={!!errors.childBirthday}
                  />
                </div>
              </td>
              <td>
                <div className="flex items-center gap-x-4">
                  <Typography
                    className={`w-[200px] ${classes.typographyLight}`}
                    gutterBottom
                    variant="body1"
                  >
                    Stripe customer ID
                  </Typography>
                  <input
                    id="customerId"
                    name="customerId"
                    placeholder="Customer ID"
                    className={`create-input  text-[color:var(--dark-charcoal)] ${
                      errors.customerId ? "error-input" : ""
                    }`}
                    value={values.customerId}
                    onChange={handleChange}
                    type="text"
                    disabled={loading}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="flex items-center gap-x-4">
                  <Typography
                    className={`w-[200px] ${classes.typographyLight}`}
                    gutterBottom
                    variant="body1"
                  >
                    Subscription Start
                  </Typography>
                  <KeyboardDatePicker
                    value={values.subscriptionStart}
                    onChange={(date) =>
                      setFieldValue("subscriptionStart", date)
                    }
                    InputProps={{
                      style: { color: "white" },
                    }}
                    KeyboardButtonProps={{
                      style: { color: "white" },
                    }}
                    format="dd/MM/yyyy"
                    variant="inline"
                    error={!!errors.subscriptionStart}
                  />
                </div>
              </td>
              <td>
                <div className="flex items-center gap-x-4">
                  <Typography
                    className={`w-[200px] ${classes.typographyLight}`}
                    gutterBottom
                    variant="body1"
                  >
                    Discount Code
                  </Typography>
                  <input
                    id="couponApplied"
                    name="couponApplied"
                    placeholder="Discount Code"
                    className={`create-input  text-[color:var(--dark-charcoal)] ${
                      errors.couponApplied ? "error-input" : ""
                    }`}
                    value={values.couponApplied}
                    onChange={handleChange}
                    type="text"
                    disabled={loading}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="flex items-center gap-x-4">
                  <Typography
                    className={`w-[200px] ${classes.typographyLight}`}
                    gutterBottom
                    variant="body1"
                  >
                    Influencer
                  </Typography>
                  <Switch
                    checked={values.influencer}
                    onChange={(_, checked) =>
                      setFieldValue("influencer", checked)
                    }
                    className="bg-[color:var(--darker-charcoal)] rounded-md"
                  />
                  {/* <Switch
        checked={enabled}
        onChange={setEnabled}
        className={`${enabled ? 'bg-teal-900' : 'bg-teal-700'}
          relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={`${enabled ? 'translate-x-9' : 'translate-x-0'}
            pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch> */}
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.influencer}
                        onChange={(_, checked) =>
                          setFieldValue("influencer", checked)
                        }
                        // style={{ background: "#fff" }}
                      />
                    }
                    label="Influencer?"
                  /> */}
                </div>
              </td>
              <td>
                <div className="flex items-center gap-x-4">
                  <Typography
                    className={`w-[200px] ${classes.typographyLight}`}
                    gutterBottom
                    variant="body1"
                  >
                    Subscription Plan <br />
                    {/* (month_1, month_9, month_12) */}
                  </Typography>
                  <select
                    id="customerId"
                    name="subscriptionType"
                    placeholder="Plan"
                    className={`create-input  text-[color:var(--dark-charcoal)] ${
                      errors.subscriptionType ? "error-input" : ""
                    }`}
                    value={values.subscriptionType}
                    onChange={handleChange}
                    disabled={loading}
                  >
                    <option value="month_1">Monthly</option>
                    <option value="month_9">Every 9 months</option>
                    <option value="month_12">Yearly</option>
                  </select>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="flex justify-center mt-16 pb-8">
          <Button
            disableElevation={true}
            variant="contained"
            endIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  clipRule="evenodd"
                />
              </svg>
            }
            className="!py-3 !px-6 !bg-[color:var(--orange)] !font-bold"
            onClick={() => handleSubmit()}
            disabled={loading}
          >
            {loading ? <BeatLoader /> : "Create Customer"}
          </Button>
        </div>
      </div>

      {/* <div className="column padding">
          <div className="row">
            <table>
              <tr>
                <th>
                  <Typography
                    className={classes.typographyLight}
                    gutterBottom
                    variant="body1"
                  >
                    Email
                  </Typography>
                </th>
                <th className={classes.tablePadding}>
                  <div className="row">
                    <input
                      id="email"
                      name="email"
                      placeholder="Email"
                      className={`create-input ${
                        errors.email ? "error-input" : ""
                      }`}
                      value={values.email}
                      onChange={handleChange}
                      type="email"
                      disabled={loading}
                    />
                  </div>
                </th>
              </tr>
              <tr>
                <th>
                  <Typography
                    className={classes.typographyLight}
                    gutterBottom
                    variant="body1"
                  >
                    Child
                  </Typography>
                </th>
                <th className={classes.tablePadding}>
                  <div className="row">
                    <input
                      id="childName"
                      name="childName"
                      placeholder="Child's Name"
                      className={`create-input ${
                        errors.childName ? "error-input" : ""
                      }`}
                      value={values.childName}
                      onChange={handleChange}
                      type="text"
                      disabled={loading}
                    />
                  </div>
                </th>
              </tr>
              <tr>
                <th>
                  <Typography
                    className={classes.typographyLight}
                    gutterBottom
                    variant="body1"
                  >
                    Stripe customer ID
                  </Typography>
                </th>
                <th className={classes.tablePadding}>
                  <div className="row">
                    <input
                      id="customerId"
                      name="customerId"
                      placeholder="Customer ID"
                      className={`create-input ${
                        errors.customerId ? "error-input" : ""
                      }`}
                      value={values.customerId}
                      onChange={handleChange}
                      type="text"
                      disabled={loading}
                    />
                  </div>
                </th>
              </tr>
              <tr>
                <th>
                  <Typography
                    className={classes.typographyLight}
                    gutterBottom
                    variant="body1"
                  >
                    Discount Code
                  </Typography>
                </th>
                <th className={classes.tablePadding}>
                  <div className="row">
                    <input
                      id="couponApplied"
                      name="couponApplied"
                      placeholder="Discount Code"
                      className={`create-input ${
                        errors.couponApplied ? "error-input" : ""
                      }`}
                      value={values.couponApplied}
                      onChange={handleChange}
                      type="text"
                      disabled={loading}
                    />
                  </div>
                </th>
              </tr>
              <tr>
                <th>
                  <Typography
                    className={classes.typographyLight}
                    gutterBottom
                    variant="body1"
                  >
                    Influencer
                  </Typography>
                </th>
                <th className={classes.tablePadding}>
                  <div className="row">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.influencer}
                          onChange={(_, checked) =>
                            setFieldValue("influencer", checked)
                          }
                          style={{ background: "#fff" }}
                        />
                      }
                      label="Influencer?"
                    />
                  </div>
                </th>
              </tr>
            </table>
            <table>
              <tr>
                <th>
                  <Typography
                    className={classes.typographyLight}
                    gutterBottom
                    variant="body1"
                  >
                    Phone Number
                  </Typography>
                </th>
                <th className={classes.tablePadding}>
                  <div className="row">
                    <input
                      id="phone"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      placeholder="Phone Number"
                      className={`create-input ${
                        errors.phone ? "error-input" : ""
                      }`}
                      type="text"
                      disabled={loading}
                    />
                  </div>
                </th>
              </tr>
              <tr>
                <th>
                  <Typography
                    className={classes.typographyLight}
                    gutterBottom
                    variant="body1"
                  >
                    Shipping Address
                  </Typography>
                </th>
                <th className={classes.tablePadding}>
                  <div className="row">
                    <textarea
                      id="shippingAddress"
                      name="shippingAddress"
                      value={values.shippingAddress}
                      onChange={handleChange}
                      placeholder="Shipping Address"
                      className={`create-input ${
                        errors.shippingAddress ? "error-input" : ""
                      }`}
                      rows={4}
                      disabled={loading}
                    />
                  </div>
                </th>
              </tr>
              <tr>
                <th>
                  <Typography
                    className={classes.typographyLight}
                    gutterBottom
                    variant="body1"
                  >
                    Instagram Handle
                  </Typography>
                </th>
                <th className={classes.tablePadding}>
                  <div className="row">
                    <input
                      id="instagramAccount"
                      name="instagramAccount"
                      value={values.instagramAccount}
                      onChange={handleChange}
                      placeholder="Instagram Handle"
                      className={`create-input ${
                        errors.instagramAccount ? "error-input" : ""
                      }`}
                      type="text"
                      disabled={loading}
                    />
                  </div>
                </th>
              </tr>
              <tr>
                <th>
                  <Typography
                    className={classes.typographyLight}
                    gutterBottom
                    variant="body1"
                  >
                    Child Birthday
                  </Typography>
                </th>
                <th className={classes.tablePadding}>
                  <div className="row">
                    <KeyboardDatePicker
                      value={values.childBirthday}
                      onChange={(date) => setFieldValue("childBirthday", date)}
                      format="dd/MM/yyyy"
                      variant="inline"
                      error={!!errors.childBirthday}
                    />
                  </div>
                </th>
              </tr>
              <tr>
                <th>
                  <Typography
                    className={classes.typographyLight}
                    gutterBottom
                    variant="body1"
                  >
                    Subscription Plan <br />
                    (month_1, month_9, month_12) 
                  </Typography>
                </th>
                <th className={classes.tablePadding}>
                  <div className="row">
                    <select
                      id="customerId"
                      name="subscriptionType"
                      placeholder="Plan"
                      className={`create-input ${
                        errors.subscriptionType ? "error-input" : ""
                      }`}
                      value={values.subscriptionType}
                      onChange={handleChange}
                      disabled={loading}
                    >
                      <option value="month_1">Monthly</option>
                      <option value="month_9">Every 9 months</option>
                      <option value="month_12">Yearly</option>
                    </select>
                  </div>
                </th>
              </tr>
              <tr>
                <th>
                  <Typography
                    className={classes.typographyLight}
                    gutterBottom
                    variant="body1"
                  >
                    Subscription Start
                  </Typography>
                </th>
                <th className={classes.tablePadding}>
                  <div className="row">
                    <KeyboardDatePicker
                      value={values.subscriptionStart}
                      onChange={(date) =>
                        setFieldValue("subscriptionStart", date)
                      }
                      format="dd/MM/yyyy"
                      variant="inline"
                      error={!!errors.subscriptionStart}
                    />
                  </div>
                </th>
              </tr>
            </table>
          </div>
        </div> */}
    </main>
  );
};

export default CreateCustomer;
