/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { toast } from "react-toastify";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
// components
// import Lookup from "../../../components/dashboard/lookup";
// import Confirmed from "../../../components/dashboard/confirmed";
// import TemplatesTable from "../../../components/TemplatesTable";
import DashboardTable from "../../../components/dashboard-table";
// import EmailHistoryTable from "../../../components/EmailHistoryTable";
//misc
import { RootState } from "../../../store/root";
import { Customer, Influencer } from "../../../@types";
// services
import { ShipmentRequest } from "../../../network/requests/shipment.request";
import { CustomersRequest } from "../../../network/requests/customer.request";
import { InfluencerRequest } from "../../../network/requests/influencer.request";

// import Email from "../components/email";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const auth = useSelector((state: RootState) => state.auth);
  const { activeSidebar, activePage, searchInput, selectedCustomerTab } =
    useSelector((state: RootState) => state.misc);

  const [loading, setLoading] = React.useState(false);
  // const [searchInput, setSearchInput] = React.useState("");
  const [searchData, setSearchData] = React.useState<any[]>([]);
  const [abandoned, setAbandoned] = React.useState<Customer[]>([]);
  const [customers, setCustomers] = React.useState<Customer[]>([]);
  const [menuCollapse, setMenuCollapse] = useState<boolean>(false);
  const [cancelledCustomers, setCancelledCustomers] = React.useState<
    Customer[]
  >([]);
  const [influencers, setInfluencers] = React.useState<Influencer[]>([]);

  React.useEffect(() => {
    if (!auth.authenticated) {
      navigate("/login", { replace: true });
    }
  }, [auth, location]);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const customers: Customer[] = await CustomersRequest.FetchAll();
        const customersWithShipment: any[] = [];

        // customers.forEach(async (customer) => {
        //   const shipments = await ShipmentRequest.FetchAllWithEmail(
        //     customer.email
        //   );

        //   customersWithShipment.push({
        //     ...customer,
        //     box_type:
        //       shipments.filter(
        //         (shipment: any) => shipment.email === customer.email
        //       )[0]?.box_type ?? "---",
        //     date_shipped:
        //       shipments.filter(
        //         (shipment: any) => shipment.email === customer.email
        //       )[0]?.date_shipped ?? "---",
        //   });
        // });

        console.log({customers});
        

        const influencers = await InfluencerRequest.FetchAll();
        const abandoned = await CustomersRequest.FetchAbandonedCustomers();

        setCustomers(
          customers.filter(
            (customer) =>
              customer.name !== '' &&
              !customer.cancelled
          )
        );
        setCancelledCustomers(
          customers.filter(
            (customer) =>
              (customer.name != null ||
                customer.phone != null ||
                customer.cancelled == null) &&
              customer.cancelled
          )
        );
        setInfluencers(influencers);
        setAbandoned(abandoned.data);
        setLoading(false);
      } catch (error) {
        toast.error("Unable to fetch customers, try again later");
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    if (activeSidebar === "customers" && searchInput) {
      const filteredValues = customers.filter(
        (customer) =>
          customer.email
            .toLowerCase()
            .includes(searchInput.toLowerCase().trim()) ||
          customer.name.toLowerCase().includes(searchInput.toLowerCase().trim())
      );
      setSearchData(filteredValues);
    } else if (activeSidebar === "cancelled" && searchInput) {
      const filteredValues = customers.filter(
        (customer) =>
          customer.email
            .toLowerCase()
            .includes(searchInput.toLowerCase().trim()) ||
          customer.name.toLowerCase().includes(searchInput.toLowerCase().trim())
      );
      setSearchData(filteredValues);
    } else if (activeSidebar === "influencers" && searchInput) {
      const filteredValues = influencers.filter(
        (influencer) =>
          influencer.email
            .toLowerCase()
            .includes(searchInput.toLowerCase().trim()) ||
          influencer.first_name
            .toLowerCase()
            .includes(searchInput.toLowerCase().trim()) ||
          influencer.last_name
            .toLowerCase()
            .includes(searchInput.toLowerCase().trim())
      );
      setSearchData(filteredValues);
    } else if (activeSidebar === "abandoned" && searchInput) {
      const filteredValues = influencers.filter(
        (customer) =>
          customer.email
            .toLowerCase()
            .includes(searchInput.toLowerCase().trim()) ||
          customer.first_name
            .toLowerCase()
            .includes(searchInput.toLowerCase().trim()) ||
          customer.last_name
            .toLowerCase()
            .includes(searchInput.toLowerCase().trim())
      );
      setSearchData(filteredValues);
    } else setSearchData([]);
  }, [searchInput, activeSidebar, customers, influencers, cancelledCustomers]);

  // const paginatedDocuments = React.useCallback(() => {
  //   if (searchInput) {
  //     return searchData.slice(
  //       K.PAGE_COUNT * (activePage - 1),
  //       K.PAGE_COUNT * activePage
  //     );
  //   }
  //   if (activeSidebar === "customers") {
  //     return customers.slice(
  //       K.PAGE_COUNT * (activePage - 1),
  //       activePage * K.PAGE_COUNT
  //     );
  //   } else if (activeSidebar === "abandoned") {
  //     return abandoned.slice(
  //       K.PAGE_COUNT * (activePage - 1),
  //       activePage * K.PAGE_COUNT
  //     );
  //   } else if (activeSidebar === "influencers") {
  //     return influencers.slice(
  //       K.PAGE_COUNT * (activePage - 1),
  //       activePage * K.PAGE_COUNT
  //     );
  //   } else return [];
  // }, [
  //   searchInput,
  //   activePage,
  //   searchData,
  //   activeSidebar,
  //   customers,
  //   influencers,
  //   abandoned,
  // ]);

  /**
   * gets customers that matches the appropriate criteria
   * - criteria: "live, cancelled or paused"
   *
   * @returns {Array} - customers
   */
  function getCustomers(): Customer[] {
    switch (true) {
      case activeSidebar === "customers" && selectedCustomerTab === "live":
        return searchInput ? searchData : customers;

      case activeSidebar === "customers" &&
        (selectedCustomerTab === "cancelled" ||
          selectedCustomerTab === "paused"):
        return cancelledCustomers;

      default:
        return abandoned;
    }
  }
  /**
   * gets urgent customers that matches the appropriate criteria
   *
   * @returns {Array} - customers
   */
  function urgentCustomers(): Customer[] {
    if (activeSidebar !== "customers") return [];

    return customers.filter(
      (customer) =>
        (((new Date(customer.next_box_date).getTime() - new Date().getTime()) /
          (1000 /** 1 second = 1000 milliseconds */ *
            60 /** No. of seconds in a minute */ *
            60 /** No. minutes in an hour */ *
            24)) /** No. hours in a day */ |
          0) ===
        0
    );
  }

  return (
    <>
      <Helmet title="Dashboard | Mochi Admin" />

      <section className="flex-1 mt-10">
        <section className="relative z-0">
          {/* {activeSidebar === "lookup" ? (
            <Lookup />
          ) : activeSidebar === "influencers" ? (
            <Confirmed />
          ) : activeSidebar === "templates" ? (
            <TemplatesTable />
          ) : activeSidebar === "emails" ? (
            <EmailHistoryTable />
          ) : ( */}
          <DashboardTable
            searchInput={searchInput}
            urgentCustomers={urgentCustomers()}
            activeSidebar={activeSidebar}
            customers={customers}
            loading={loading}
          />
          {/* )} */}
        </section>
      </section>
    </>
  );
};

export default Dashboard;
