import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { Image } from "semantic-ui-react";
import { capitalCase } from "change-case";
import { useDispatch, useSelector } from "react-redux";

import "../css/sidebar.css";
import "react-pro-sidebar/dist/css/styles.css";

//store
import { RootState } from "../store/root";
import { logoutUser } from "../store/modules/auth.reducer";
import { setActiveSidebar } from "../store/modules/misc.reducer";
// misc
import K from "../constants";
//images
import MenuIcon from "./SVGIcons/MenuIcon";
import LogoutIcon from "./SVGIcons/LogoutIcon";
import lookup from "../assets/images/lookup.svg";
import logo from "../assets/images/mochi-logo.png";
import customers from "../assets/images/customers.svg";
import confirmed from "../assets/images/confirmed.svg";
import templates from "../assets/images/templates.svg";
import influencers from "../assets/images/influencers.svg";

const SideBar: React.FC = () => {
  const dispatch = useDispatch();
  const [menuCollapse, setMenuCollapse] = useState(false);
  const misc = useSelector((state: RootState) => state.misc);

  const sidebarItems: { sidebarItem: string; subItems: string[] }[] = [
    { sidebarItem: "customers", subItems: ["live", "paused", "abandoned"] },
    { sidebarItem: "influencers", subItems: [] },
    { sidebarItem: "templates", subItems: [] },
    { sidebarItem: "qr", subItems: [] },
  ];

  const logout = () => {
    window.localStorage.removeItem(K.LWM_ADMIN_TOKEN_KEY);
    dispatch(logoutUser());
    toast.success("Logged out successfully");
  };

  const setSidebar = (sidebar: string) => {
    dispatch(setActiveSidebar(sidebar));
  };

  const renderIcon = (sidebar: string) => {
    switch (sidebar) {
      case "customers":
        return customers;
      case "abandoned":
        return customers;
      case "cancelled":
        return customers;
      case "confirmed":
        return confirmed;
      case "lookup":
        return lookup;
      case "influencers":
        return influencers;
      default:
        return templates;
    }
  };

  return (
    <>
      <div id="header">
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader className="bg-white">
            <div className="padding">
              <Image className={`w-full h-auto rounded-full my-3`} src={logo} />
            </div>
          </SidebarHeader>
          <SidebarContent className="bg-white">
            <Menu>
              {sidebarItems.map((item, index) => (
                <MenuItem
                  // onMouseOver={()=>toast.success('over')}
                  // onMouseLeave={()=>toast.error('Leave')}

                  key={index}
                  className={
                    misc.activeSidebar === item.sidebarItem
                      ? "active sidebar-item"
                      : "inactive sidebar-item"
                  }
                  style={{
                    height: "60px",
                    transition: "ease 0.1s all",
                  }}
                  active={misc.activeSidebar === item.sidebarItem}
                  onClick={() => {
                    setSidebar(item.sidebarItem);
                    // if (history.location.pathname !== "/") {
                    //   history.push("/");
                    // }
                  }}
                >
                  <img
                    src={renderIcon(item.sidebarItem)}
                    alt="item"
                    className="item-img"
                  />
                  <span
                    style={{
                      opacity: menuCollapse ? "0" : "1",
                      transition: "all 0.2s ease-out",
                    }}
                  >
                    {capitalCase(item.sidebarItem)}
                  </span>
                </MenuItem>
              ))}
            </Menu>
          </SidebarContent>

          <SidebarFooter className="bg-white">
            <button
              className="flex justify-center w-fit mx-auto p-2 mt-2"
              onClick={() => setMenuCollapse(!menuCollapse)}
            >
              <div className="cursor-pointer">
                <MenuIcon />
              </div>
            </button>
            <Menu
              iconShape="square"
              className="flex items-center justify-center"
            >
              <button
                onClick={logout}
                className="text-black bg-[#ffcc00] mx-4 py-2 px-3 rounded-[4px] w-inherit flex items-center justify-center"
              >
                {menuCollapse ? <LogoutIcon /> : "Logout"}
              </button>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
      </div>
    </>
  );
};

export default SideBar;
