export function renderSubscriptionType(type: string): string {
  if (type) {
    return type.toLowerCase() === "month-9"
      ? "9 months"
      : type.toLowerCase() === "month-12"
      ? "12 Months"
      : type.toLowerCase() === "month-6"
      ? "6 Months"
      : "Monthly";
  } else {
    return type === "month-9"
      ? "9 months"
      : type === "month-12"
      ? "12 Months"
      : type === "month-6"
      ? "6 Months"
      : "Monthly";
  }
}
