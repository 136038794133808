import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { ToastContainer } from "react-toastify";
import { HelmetProvider } from "react-helmet-async";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Routes, Route, useLocation } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AnimatePresence } from "framer-motion/dist/framer-motion";

// misc
import "./App.css";
import K from "./constants";
import "react-toastify/dist/ReactToastify.min.css";
import setAuthToken from "./network/set-auth-token";
import DashboardLayout from "./components/layout/DashboardLayout";

// pages
// import Edit from "./pages/edit";
import SignIn from "./pages/login";
import IgDetails from "./pages/IgDetails";
import Create from "./pages/customers/New";
import Templates from "./pages/templates/Templates";
import Customers from "./pages/customers/Home/Customers";
import Influencers from "./pages/influencers/Influencers";
import SubscriptionDetails from "./pages/customers/SubscriptionDetails/SubscriptionDetails";

// components
import CustomerEdit from "./pages/customers/Edit/Edit";
import CustomersHeader from "./components/layout/Headers/Customers";
import TemplatesHeader from "./components/layout/Headers/Templates";
import ProductQR from "./components/product-qr";

const HEADERS: { [key: string]: React.ReactNode } = {
  "/": <CustomersHeader />,
  // "/influencers": <InfluencersHeader />,
  "/templates": <TemplatesHeader />,
};

const App: React.FC = () => {
  const location = useLocation();
  // check if token exists and save to auth token
  React.useEffect(() => {
    const token = window.localStorage.getItem(K.LWM_ADMIN_TOKEN_KEY);
    if (token) {
      setAuthToken(token);
    }
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <HelmetProvider>
        <CssBaseline />
        <AnimatePresence exitBeforeEnter>
          <Routes>
            <Route
              path="/"
              element={
                <DashboardLayout
                  headerWrapperClass={
                    location.pathname === "/"
                      ? "bg-[color:var(--charcoal)]"
                      : ""
                  }
                  header={HEADERS[location.pathname]}
                />
              }
            >
              <Route index element={<Customers />} />
              <Route path="create" element={<Create />} />
              <Route path="product-qr" element={<ProductQR />} />
              <Route path="customers/:id" element={<SubscriptionDetails />} />
              <Route path="influencers">
                <Route index element={<Influencers />} />
                <Route path=":email" element={<IgDetails />} />
              </Route>
              <Route path="templates">
                <Route index element={<Templates />} />
                <Route path=":email" element={<IgDetails />} />
              </Route>
              <Route path=":id/edit" element={<CustomerEdit />} />
            </Route>
            <Route path="/login" element={<SignIn />} />
          </Routes>
        </AnimatePresence>

        {/* toast container */}
        <ToastContainer />
      </HelmetProvider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
