/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
// import "../../../css/create.css";

import { toast } from "react-toastify";
import styled from "styled-components";
import { DayPicker } from "react-day-picker";
import { BeatLoader } from "react-spinners";
import { Web, FileCopy } from "@material-ui/icons";

import "react-day-picker/dist/style.css";

// misc
import api from "../../../network/api";
import { Customer } from "../../../@types";
import { RootState } from "../../../store/root";
import BackNavigator from "../../../components/shared/buttons/BackNavigator/BackNavigator";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      fontWeight: "bold",
      color: "white",
      justifyContent: "flex-end",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    typographyLight: {
      color: "grey",
      fontWeight: "bold",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    tablePadding: {
      paddingLeft: "50px",
      paddingRight: "50px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    bold: {
      fontWeight: "bold",
      color: "white",
    },
    root: {
      width: "100%",
      backgroundColor: "black",
      // color:"white"
    },
    chip: {
      borderRadius: "5px",
      fontWeight: "bold",
      marginLeft: "12px",
      marginBottom: "10px",
      height: "20px",
      color: "green",
      backgroundColor: "#90ee90",
    },
    section1: {
      margin: theme.spacing(3, 2),
      backgroundColor: "black",
    },
    section2: {
      margin: theme.spacing(2),
    },
    section3: {
      margin: theme.spacing(3, 1, 1),
    },
  })
);

const CustomerEdit: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ id: string }>();
  const auth = useSelector((state: RootState) => state.auth);

  const [user, setUser] = React.useState<{
    customer: Customer;
    stripeCustomer?: any;
  }>();
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [childName, setChildName] = React.useState("");
  const [generatedURL, setGeneratedURL] = React.useState("");
  const [childBirthday, setChildBirthday] = React.useState("");
  const [couponApplied, setCouponApplied] = React.useState("");
  const [shippingAddress, setShippingAddress] = React.useState("");
  const [instagramAccount, setInstagramAccount] = React.useState("");

  React.useEffect(() => {
    if (!auth.authenticated) {
      navigate("/login");
    }
  }, [auth, location]);

  React.useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const { data } = await api.get<{
          customer: Customer;
          stripeCustomer?: any;
        }>(`/customers/id/${params.id}`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        });
        setUser(data);
        setLoading(false);
      } catch (error) {
        toast.error("");
        setLoading(false);
      }
    };
    fetchCustomer();
  }, [params.id]);

  if (loading) {
    return (
      <div className="container">
        <div className="main">
          <LoadingDiv>
            <BeatLoader />
          </LoadingDiv>
        </div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="container">
        <div className="main">
          <LoadingDiv>
            <h2>No such customer with given id!</h2>
          </LoadingDiv>
        </div>
      </div>
    );
  }

  const saveField = async (field: string, value: any) => {
    try {
      await api.put(`/customers/${params.id}`, {
        [field]: value,
      });
      toast.success(`Updated field ${field}`);
    } catch (error) {
      toast.error("Unable to update field");
    }
  };

  const fetchGeneratedURL = async () => {
    try {
      const { data } = await api.post<{ url: string }>(
        "/stripe/portal",
        {
          email: user.customer.email,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      );
      setGeneratedURL(data.url);
    } catch (error) {
      toast.error("Unable to generate portal URL for customer");
    }
  };

  return (
    <div className="container">
      <main className="mx-auto my-8 p-6 rounded-md bg-[color:var(--charcoal)]">
        <div>
          <div>
            <Grid container>
              <div className="row">
                <Grid item xs>
                  <div className="row px-4">
                    <BackNavigator />
                    <div style={{ marginLeft: "12px" }}>
                      <Typography
                        className={classes.typography}
                        gutterBottom
                        variant="body1"
                      >
                        SUBSCRIPTION
                      </Typography>
                    </div>
                  </div>
                  <div className="row center px-4">
                    <input
                      id="name"
                      name="name"
                      style={{ fontSize: "35px" }}
                      placeholder="Customer Name"
                      className="my-6 p-3 rounded-md text-[color:var(--dark-charcoal)]"
                      value={name || user.customer.name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                    />

                    <Button
                      disableElevation={true}
                      variant="contained"
                      style={{
                        marginLeft: "25px",
                        backgroundColor: "#ffc000",
                        color: "#000",
                        fontWeight: "bold",
                        borderRadius: "8px",
                      }}
                      onClick={() => saveField("name", name)}
                    >
                      SAVE
                    </Button>
                  </div>
                </Grid>
                <div className="float-right" style={{ alignItems: "flex-end" }}>
                  <div className="column"></div>
                </div>
              </div>
            </Grid>
          </div>
          <Divider variant="middle" />

          <br />
          <div className="padding">
            <Typography className={classes.bold} gutterBottom variant="h6">
              Customer Details
            </Typography>
          </div>
          <Divider variant="middle" />
          <br />
          <div className="column padding">
            <div className="row">
              <table>
                <tr>
                  <th>
                    <Typography
                      className={classes.typographyLight}
                      gutterBottom
                      variant="body1"
                    >
                      Discount Code
                    </Typography>
                  </th>
                  <th className={classes.tablePadding}>
                    <div className="row">
                      <input
                        id="discount"
                        name="discount"
                        placeholder="Discount Code"
                        className="create-input text-[color:var(--dark-charcoal)]"
                        value={
                          couponApplied || user.customer.couponApplied || ""
                        }
                        onChange={(e) => setCouponApplied(e.target.value)}
                        type="text"
                      />
                      <Button
                        disableElevation={true}
                        variant="contained"
                        style={{
                          marginTop: "1rem",
                          marginLeft: "15px",
                          backgroundColor: "#ffc000",
                          color: "#000",
                          fontWeight: "bold",
                          borderRadius: "8px",
                        }}
                        onClick={() =>
                          saveField("couponApplied", couponApplied)
                        }
                      >
                        SAVE
                      </Button>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>
                    <Typography
                      className={classes.typographyLight}
                      gutterBottom
                      variant="body1"
                    >
                      Instagram Handle
                    </Typography>
                  </th>
                  <th className={classes.tablePadding}>
                    <div className="row">
                      <input
                        id="instagramAccount"
                        name="instagramAccount"
                        placeholder="Instagram Handle"
                        className="create-input text-[color:var(--dark-charcoal)]"
                        value={
                          instagramAccount ||
                          user.customer.instagramAccount ||
                          ""
                        }
                        onChange={(e) => setInstagramAccount(e.target.value)}
                        type="text"
                      />

                      <Button
                        disableElevation={true}
                        variant="contained"
                        style={{
                          marginTop: "1rem",
                          marginLeft: "15px",
                          backgroundColor: "#ffc000",
                          color: "#000",
                          fontWeight: "bold",
                          borderRadius: "8px",
                        }}
                        onClick={() =>
                          saveField("instagramAccount", instagramAccount)
                        }
                      >
                        SAVE
                      </Button>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th className="flex items-start mt-5">
                    <Typography
                      className={classes.typographyLight}
                      gutterBottom
                      variant="body1"
                    >
                      Shipping Address
                    </Typography>
                  </th>
                  <th className={classes.tablePadding}>
                    <div className="row">
                      <textarea
                        id="shippingAddress"
                        name="shippingAddress"
                        placeholder="Shipping Address"
                        className="create-input text-[color:var(--dark-charcoal)]"
                        defaultValue={` ${user.customer.shippingAddress} ${user.customer.apartment} ${user.customer.city} ${user.customer.country} `}
                        onChange={(e) => setShippingAddress(e.target.value)}
                        rows={5}
                      />
                      <Button
                        disableElevation={true}
                        variant="contained"
                        style={{
                          marginTop: "1rem",
                          marginLeft: "15px",
                          backgroundColor: "#ffc000",
                          color: "#000",
                          fontWeight: "bold",
                          borderRadius: "8px",
                        }}
                        onClick={() =>
                          saveField("shippingAddress", shippingAddress)
                        }
                      >
                        SAVE
                      </Button>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th className="flex items-start mt-5">
                    <Typography
                      className={classes.typographyLight}
                      gutterBottom
                      variant="body1"
                    >
                      Child Birthday
                    </Typography>
                  </th>
                  <th className={classes.tablePadding}>
                    <div className="row">
                      <DayPicker
                        className="create-input text-[color:var(--dark-charcoal)]"
                        selectedDays={
                          new Date(childBirthday || user.customer.childBirthday)
                        }
                        showOutsideDays
                        onDayClick={(day: any) => {
                          setChildBirthday(day.toString());
                        }}
                      />
                      <Button
                        disableElevation={true}
                        variant="contained"
                        style={{
                          marginTop: "1rem",
                          marginLeft: "15px",
                          backgroundColor: "#ffc000",
                          color: "#000",
                          fontWeight: "bold",
                          borderRadius: "8px",
                        }}
                        onClick={() =>
                          saveField("childBirthday", childBirthday)
                        }
                      >
                        SAVE
                      </Button>
                    </div>
                  </th>
                </tr>
              </table>
              <table>
                <tr>
                  <th>
                    <Typography
                      className={classes.typographyLight}
                      gutterBottom
                      variant="body1"
                    >
                      Phone Number
                    </Typography>
                  </th>
                  <th className={classes.tablePadding}>
                    <div className="row">
                      <input
                        id="phone"
                        name="phone"
                        placeholder="Phone Number"
                        className="create-input text-[color:var(--dark-charcoal)]"
                        value={phone || user.customer.phone}
                        onChange={(e) => setPhone(e.target.value)}
                        type="text"
                      />

                      <Button
                        disableElevation={true}
                        variant="contained"
                        style={{
                          marginTop: "1rem",
                          marginLeft: "15px",
                          backgroundColor: "#ffc000",
                          color: "#000",
                          fontWeight: "bold",
                          borderRadius: "8px",
                        }}
                        onClick={() => saveField("phone", phone)}
                      >
                        SAVE
                      </Button>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>
                    <Typography
                      className={classes.typographyLight}
                      gutterBottom
                      variant="body1"
                    >
                      Child
                    </Typography>
                  </th>
                  <th className={classes.tablePadding}>
                    <div className="row">
                      <input
                        id="childName"
                        name="childName"
                        placeholder="Child's Name"
                        className="create-input text-[color:var(--dark-charcoal)]"
                        value={childName || user.customer.childName || ""}
                        onChange={(e) => setChildName(e.target.value)}
                        type="text"
                      />
                      <Button
                        disableElevation={true}
                        variant="contained"
                        style={{
                          marginTop: "1rem",
                          marginLeft: "15px",
                          backgroundColor: "#ffc000",
                          color: "#000",
                          fontWeight: "bold",
                          borderRadius: "8px",
                        }}
                        onClick={() => saveField("childName", childName)}
                      >
                        SAVE
                      </Button>
                    </div>
                  </th>
                </tr>

                <tr>
                  <th className="flex items-start mt-5">
                    <Typography
                      className={classes.typographyLight}
                      gutterBottom
                      variant="body1"
                    >
                      Country
                    </Typography>
                  </th>
                  <th className={classes.tablePadding}>
                    <div className="row">
                      <textarea
                        id="country"
                        name="country"
                        placeholder="Country"
                        className="create-input text-[color:var(--dark-charcoal)]"
                        defaultValue={user.customer.country || ""}
                        onChange={(e) => setCountry(e.target.value)}
                        rows={5}
                      />
                      <Button
                        disableElevation={true}
                        variant="contained"
                        style={{
                          marginTop: "1rem",
                          marginLeft: "15px",
                          backgroundColor: "#ffc000",
                          color: "#000",
                          fontWeight: "bold",
                          borderRadius: "8px",
                        }}
                        onClick={() => saveField("country", country)}
                      >
                        SAVE
                      </Button>
                    </div>
                  </th>
                </tr>
              </table>
            </div>
          </div>

          <br />
          <Divider variant="middle" />
          <br />
        </div>
        <br />
        {user.stripeCustomer?.id && (
          <>
            <Button
              disableElevation={true}
              variant="contained"
              style={{
                marginTop: "1rem",
                marginLeft: "15px",
                backgroundColor: "#ffc000",
                color: "#000",
                fontWeight: "bold",
                borderRadius: "8px",
              }}
              onClick={fetchGeneratedURL}
            >
              Generate URL for client to update billing <Web />
            </Button>
            <br />
            <input
              placeholder="Generated URL"
              disabled
              value={generatedURL}
              className="create-input text-[color:var(--dark-charcoal)]"
              type="text"
              style={{
                marginLeft: "25px",
                marginBottom: "5rem",
                marginTop: ".5rem",
                width: "40rem",
              }}
            />
            <FileCopy
              style={{
                cursor: "pointer",
                marginTop: "1rem",
                marginLeft: "1rem",
              }}
            />
          </>
        )}
        <br />
      </main>
    </div>
  );
};

const LoadingDiv = styled.div`
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default CustomerEdit;
