/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { toast } from "react-toastify";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ProductBoxList from "../../../components/product-box-list";
import SubscriptionPricingTable from "../../../components/subscription-pricing-table";
import NestedGrid from "../../../components/product-image-grid";
import Sync from "@material-ui/icons/Sync";
import FileCopy from "@material-ui/icons/FileCopy";
import { format, addMonths } from "date-fns";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { useClipboard } from "use-clipboard-copy";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AmazonCharge, Box, Customer, Shipment } from "../../../@types";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import api from "../../../network/api";
import { RootState } from "../../../store/root";
import SubscriptionActions from "../../../components/subscriptions-actions";

import CircularLoading from "../../../components/Animations/Loading";
import { renderSubscriptionType } from "../../../shared/functions";
import { usePopperTooltip } from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";
import ClipboardButton from "../../../components/shared/buttons/Clipboard/Clipboard";
import BackNavigator from "../../../components/shared/buttons/BackNavigator/BackNavigator";
import ShipmentItem from "../../../components/Shipment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    whiteButton: {},
    typography: {
      fontWeight: "bold",
      color: "#eee",
      justifyContent: "flex-end",
    },
    typographyLight: {
      color: "grey",
      fontWeight: "bold",
    },
    tablePadding: {
      paddingLeft: "100px",
      paddingRight: "100px",
    },
    th: {
      backgroundColor: "red",
    },
    bold: {
      fontWeight: "bold",
    },
    root: {
      width: "100%",
      // backgroundColor: "#000",
      color: "#fff",
    },
    chip: {
      borderRadius: "5px",
      fontWeight: "bold",
      // marginLeft: "12px",
      // marginBottom: "10px",
      height: "20px",
      color: "green",
      backgroundColor: "#90ee90",
    },
    month1chip: {
      borderRadius: "5px",
      fontWeight: "bold",
      // marginLeft: "12px",
      // marginBottom: "10px",
      height: "20px",
      color: "#2D2926FF",
      backgroundColor: "#E94B3CFF",
    },
    month9chip: {
      borderRadius: "5px",
      fontWeight: "bold",
      // marginLeft: "12px",
      // marginBottom: "10px",
      height: "20px",
      color: "#00B1D2FF",
      backgroundColor: "#FDDB27FF",
    },
    month12chip: {
      borderRadius: "5px",
      fontWeight: "bold",
      // marginLeft: "12px",
      // marginBottom: "10px",
      height: "20px",
      color: "#FFE77AFF",
      backgroundColor: "#2C5F2DFF",
    },
    notActive: {
      borderRadius: "5px",
      fontWeight: "bold",
      // marginLeft: "12px",
      // marginBottom: "10px",
      height: "20px",
      color: "white",
      backgroundColor: "#eb1010",
    },
    section1: {
      margin: theme.spacing(3, 2),
    },
    section2: {
      margin: theme.spacing(2),
    },
    section3: {
      margin: theme.spacing(3, 1, 1),
    },
  })
);

const SubscriptionDetails: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [boxType, setBoxType] = useState<Box>();
  const [open, setOpen] = React.useState(false);
  const [customer, setCustomer] = React.useState<{
    customer: Customer;
    stripeCustomer?: any;
  }>();
  const [trackingLink, setTrackingLink] = useState("");
  const [showBoxToday, setShowBoxToday] = useState(false);
  const auth = useSelector((state: RootState) => state.auth);
  const [generatedURL, setGeneratedURL] = React.useState("");
  const [nextBoxDate, setNextBoxDate] = React.useState(new Date());
  const [amazonCharges, setAmazonCharges] = useState<AmazonCharge[]>([]);
  const [showNextBoxDatePicker, setShowNextBoxDatePicker] = useState(false);

  const {
    visible,
    getArrowProps,
    setTooltipRef,
    setTriggerRef,
    getTooltipProps,
  } = usePopperTooltip();

  //My shipments
  const [shipments, setShipments] = useState<Shipment[]>([]);
  let [shipmentYears, setShipmentYears] = useState<string[]>([]);

  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const anchorRef2 = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const fetchGeneratedURL = async () => {
    try {
      const { data } = await api.post<{ url: string }>(
        "/stripe/portal",
        {
          email: customer?.customer.email,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      );
      window.open(data.toString(), "_blank");
    } catch (error) {
      toast.error("Unable to generate portal URL for customer");
    }
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const clipboard = useClipboard();

  React.useEffect(() => {
    if (!auth.authenticated) {
      navigate("/login");
    }
  }, [auth.authenticated, location]);

  React.useEffect(() => {
    setLoading(true);

    const fetchCustomerData = async () => {
      try {
        const { data } = await api.get<{
          customer: Customer;
          stripeCustomer?: any;
        }>(`/customers/id/${params.id}`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        });

        const { data: shipments } = await api.get(
          `/shipments/${data.customer.email}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
          }
        );

        if (data.customer.isAmazon) {
          try {
            await api
              .get<AmazonCharge[]>(
                `/customers/amazon-charges/${data.customer.email}`,
                {
                  headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
                  },
                }
              )
              .then((result) => {
                console.log("charges", result.data);
                // setAmazonCharges(result.data);
              });
          } catch (error) {}
        }

        //Get shipment years and sort
        shipments.forEach((shipment: any) => {
          if (
            !shipmentYears.includes(
              format(new Date(shipment.date_shipped), "yyyy")
            )
          ) {
            shipmentYears.push(format(new Date(shipment.date_shipped), "yyyy"));
          }
        });
        setShipmentYears(shipmentYears);

        setCustomer(data);
        console.log(data);

        setShipments(shipments);
        setLoading(false);

        if (data.customer.next_box_date) {
          setNextBoxDate(
            new Date(
              new Date(data.customer.next_box_date).setMonth(
                new Date(data.customer.next_box_date).getMonth() + 1
              )
            )
          );
        }
      } catch (error) {
        toast.error(`Unable to fetch customer with id ${params.id}`);
        setLoading(false);
      }
    };
    fetchCustomerData();
  }, [params.id]);

  if (loading) {
    return (
      <div className="container">
        {/* <aside className="sidebar">
          <SideBar />
        </aside> */}
        <LoadDiv>
          {/* <BeatLoader /> */}
          <CircularLoading />
        </LoadDiv>
      </div>
    );
  }

  if (!customer) {
    return (
      <div className="container">
        {/* <aside className="sidebar">
          <SideBar />
        </aside> */}
        <LoadDiv>
          <h3>Customer Not Found!</h3>
          <Button color="primary" onClick={() => navigate(-1)}>
            GO BACK
          </Button>
        </LoadDiv>
      </div>
    );
  }

  const getNextBoxDay = () => {
    let next_day =
      ((new Date(customer.customer.next_box_date).getTime() -
        new Date().getTime()) /
        (1000 * 3600 * 24)) |
      0;

    return next_day;
  };

  const renderNextInvoice = () => {
    switch (customer.customer.subscriptionType) {
      case "month-1":
        return `$41.60 on ${format(
          addMonths(new Date(customer.customer.subscriptionStart), 1),
          "do LLLL, yyyy"
        )}`;
      case "month-9":
        return `$332.80 on ${format(
          addMonths(new Date(customer.customer.subscriptionStart), 9),
          "do LLLL, yyyy"
        )}`;
      case "month-6":
        return `$221.70 on ${format(
          addMonths(new Date(customer.customer.subscriptionStart), 6),
          "do LLLL, yyyy"
        )}`;
      case "month-12":
        return `$374.40 on ${format(
          addMonths(new Date(customer.customer.subscriptionStart), 12),
          "do LLLL, yyyy"
        )}`;
    }
  };

  const renderBox = (code: string) => {
    switch (code) {
      case "MCL 1":
        return "Computer Science";
      case "MCL 2":
        return "Neuroscience";
      case "MCL 3":
        return "Dinosaurs";
      case "MCL 4":
        return "Good habits";
      case "MCL 5":
        return "Words";
      case "MCL 6":
        return "Planets";
      case "MCL 7":
        return "Shapes";
      case "MCL 8":
        return "Insects and Bugs";
      case "SKU1":
        return "Mochi Screenless Coding Robotics Kit";
      case "SKU3":
        return "Learn Colors with Mochi";
      case "SKU4":
        return "Mochi Learns Numbers";
      case "SKU5":
        return "Learn Letters with Mochi";
      case "SKU6":
        return "Learn Shapes with Mochi";
      case "SKU7":
        return "Learn Animals with Mochi";
      case "SKU8":
        return "Mochi Learns Words";
      case "SKU9":
        return "Learn Fruits and Vegetables";
      case "SKU10":
        return "Learn Earth With Mochi";
      case "SKU11":
        return "Learn Time With Mochi";
      case "SKU12":
        return "Learn Biology With Mochi";
      case "SKU13":
        return "Learn Good Habits With Mochi";
    }
  };

  const renderBoxCover = (code: string) => {
    switch (code) {
      case "MCL 1":
        return "../../assets/images/nuber_systems.png";
      case "MCL 2":
        return "../../assets/images/Neuroscience.png";
      case "MCL 3":
        return "../../assets/images/Dinosaurs.png";
      case "MCL 4":
        return "../../assets/images/Good Habits.png";
      case "MCL 5":
        return "../../assets/images/Words.png";
      case "MCL 6":
        return "../../assets/images/Planets.png";
      case "MCL 7":
        return "../../assets/images/Shapes.png";
      case "MCL 8":
        return "../../assets/images/insetsandbugs.png";
      case "SKU1":
        return "../../assets/images/SKU1.webp";
      case "SKU4":
        return "../../assets/images/SKU4.webp";
      case "SKU3":
        return "../../assets/images/SKU3.webp";
      case "SKU5":
        return "../../assets/images/SKU5.webp";
      case "SKU6":
        return "../../assets/images/SKU6.webp";
      case "SKU7":
        return "../../assets/images/SKU7.webp";
      case "SKU8":
        return "../../assets/images/SKU8.webp";
      case "SKU9":
        return "../../assets/images/SKU9.webp";
      case "SKU10":
        return "../../assets/images/SKU10.webp";
      case "SKU11":
        return "../../assets/images/SKU11.webp";
      case "SKU12":
        return "../../assets/images/SKU12.webp";
      case "SKU13":
        return "../../assets/images/SKU13.webp";
      default:
        return "../../assets/images/SKU3.webp";
    }
  };

  const confirmShipment = () => {
    if (!trackingLink) {
      toast.error("Enter tracking link");
      return;
    }
    if (!boxType) {
      toast.error("Select box");
      return;
    }
    try {
      toast.success("Shipment confirmed");

      api.post("/customers/shipment", {
        name: customer.customer.name,
        email: customer.customer.email,
        tracking_link: trackingLink,
        next_box_date: nextBoxDate,
        box_type: boxType.product_code,
        date_shipped: new Date(),
      });
    } catch (error) {
      toast.error("An error occured");
    }
  };

  const confirmCustomShipment = () => {
    if (!trackingLink) {
      toast.error("Enter tracking link");
      return;
    }
    if (!boxType) {
      toast.error("Select box");
      return;
    }
    try {
      api.post("/customers/shipment/custom", {
        name: customer.customer.name,
        email: customer.customer.email,
        tracking_link: trackingLink,
        box_type: boxType.product_code,
        date_shipped: nextBoxDate,
      });
      toast.success("Shipment confirmed");
    } catch (error) {
      toast.error("An error occurred");
    }
  };

  return (
    <main className="main">
      {loading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularLoading />
        </div>
      ) : (
        <div className={classes.root}>
          <header className="top-0 sticky z-30 w-full bg-[color:var(--dark-charcoal)] py-2">
            <section className="flex items-center justify-between py-2">
              <div className="flex items-center gap-2">
                <BackNavigator />
                <Typography
                  className={classes.typography}
                  // gutterBottom
                  variant="body1"
                >
                  SUBSCRIPTION
                </Typography>
                <Chip
                  className={`ml-5 ${
                    customer.customer.subscriptionType === "month-12"
                      ? classes.month12chip
                      : customer.customer.subscriptionType === "month-9"
                      ? classes.month9chip
                      : classes.month1chip
                  }`}
                  label={renderSubscriptionType(
                    customer.customer.subscriptionType
                  )}
                />
              </div>
              <div className="flex items-center gap-5">
                <div className="flex items-center">
                  <div
                    style={{ paddingInline: "10px", color: "grey" }}
                    ref={clipboard.target}
                  >
                    {customer.customer.customerId || customer.customer.email}
                  </div>
                  <ClipboardButton
                    content={
                      customer.customer.customerId || customer.customer.email
                    }
                  />
                </div>
                {auth.authenticated && auth.user!.isAdmin && (
                  <SubscriptionActions
                    cancelled={customer.customer.cancelled}
                    customerId={customer.customer.id}
                  />
                )}
              </div>
            </section>
            <section className="flex items-center">
              <div>
                <Typography
                  className={`${classes.bold}`}
                  variant="h3"
                  ref={setTriggerRef}
                >
                  {customer.customer.name}
                </Typography>

                {visible && (
                  <div
                    ref={setTooltipRef}
                    {...getTooltipProps({
                      className: "tooltip-container",
                    })}
                  >
                    <div {...getArrowProps({ className: "tooltip-arrow" })} />
                    <div
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        fontSize: "1.1rem",
                      }}
                    >
                      {customer.customer.email}
                    </div>
                  </div>
                )}
              </div>
              <div className="ml-5">
                <Chip
                  className={
                    customer.customer.cancelled
                      ? classes.notActive
                      : classes.chip
                  }
                  label={customer.customer.cancelled ? "Not Active" : "Active"}
                />
              </div>
            </section>
          </header>
          <Divider variant="middle" />
          <br />
          <div
            style={{
              width: "500px",
              display: "flex",
              paddingBlock: "5px",
              borderRadius: "12px",
            }}
            className="bg-[color:var(--charcoal)] font-bold"
          >
            <div className="p-4">
              <Typography variant="h6" className="text-[color:var(--orange)]">
                Started
              </Typography>
              <Typography
                style={{ fontWeight: "bold" }}
                className="text-gray-300 "
                gutterBottom
                variant="body2"
              >
                {format(
                  new Date(customer.customer.subscriptionStart),
                  "do LLLL, yyyy"
                )}
              </Typography>
            </div>
            <Divider
              style={{ color: "white" }}
              orientation="vertical"
              flexItem
            />
            <div className="p-4">
              <Typography variant="h6" className="text-[color:var(--orange)]">
                Next Invoice
              </Typography>
              <Typography
                className="text-blue-400"
                style={{ fontWeight: "bold" }}
                gutterBottom
                variant="body2"
              >
                {renderNextInvoice()}
              </Typography>
            </div>
          </div>

          <br />
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "30px",
              alignItems: "center",
              // justifyContent: "space-between",
            }}
            className="padding"
          >
            <Typography className={classes.bold} gutterBottom variant="h6">
              Subscription Details{" "}
            </Typography>
            {auth.authenticated && auth.user!.isAdmin && (
              <Button
                style={{
                  backgroundColor: "#5469D4",
                  color: "#fff",
                  borderRadius: "8px",
                  // marginRight: "30px",
                  display:
                    customer.customer.portalURL &&
                    customer.customer.portalURL.length > 0
                      ? "block"
                      : "none",
                }}
                onClick={() => {
                  toast.success("Generating Link");
                  fetchGeneratedURL();
                }}
              >
                Manage portal
              </Button>
            )}
          </div>
          <Divider style={{ color: "white" }} variant="middle" />
          <br />
          <div className="column padding">
            <div className="row customerdetailstable">
              <table>
                <tr>
                  <th>
                    <Typography
                      className={classes.typographyLight}
                      gutterBottom
                      variant="body1"
                    >
                      Customer
                    </Typography>
                  </th>
                  <th className={classes.tablePadding}>
                    <div className="row">
                      {/* <AccountCircle style={{ color: "#5469D4" }} /> */}
                      <Typography
                        className={`text-blue-400 ${classes.bold}`}
                        gutterBottom
                        variant="body1"
                      >
                        {customer.customer.name}
                      </Typography>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>
                    <Typography
                      className={classes.typographyLight}
                      gutterBottom
                      variant="body1"
                    >
                      Created
                    </Typography>
                  </th>
                  <th className={classes.tablePadding}>
                    <div style={{}}>
                      {customer.stripeCustomer?.created
                        ? format(
                            new Date(customer.stripeCustomer.created),
                            "LLLL dd hh:mm"
                          )
                        : format(
                            new Date(customer.customer.subscriptionStart),
                            "LLLL dd hh:mm"
                          )}
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>
                    <Typography
                      className={classes.typographyLight}
                      gutterBottom
                      variant="body1"
                    >
                      Current Period
                    </Typography>
                  </th>
                  <th className={classes.tablePadding}>
                    <div style={{}}>
                      {format(
                        new Date(customer.customer.subscriptionStart),
                        "LLLL dd"
                      )}{" "}
                      to{" "}
                      {format(
                        addMonths(
                          new Date(customer.customer.subscriptionStart),
                          customer.customer.subscriptionType === "month_12"
                            ? 12
                            : customer.customer.subscriptionType === "month_9"
                            ? 6
                            : 1
                        ),
                        "LLLL dd, yyyy"
                      )}
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>
                    <Typography
                      className={classes.typographyLight}
                      gutterBottom
                      variant="body1"
                    >
                      ID
                    </Typography>
                  </th>
                  <th className={classes.tablePadding}>
                    <div className="flex items-center">
                      <div style={{ paddingRight: "12px" }}>
                        {customer.customer.customerId ||
                          customer.customer.email}
                      </div>
                      <ClipboardButton
                        content={
                          customer.customer.customerId ||
                          customer.customer.email
                        }
                      />
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>
                    <Typography
                      className={classes.typographyLight}
                      gutterBottom
                      variant="body1"
                    >
                      Child
                    </Typography>
                  </th>
                  <th className={classes.tablePadding}>
                    <div style={{}}>
                      {customer.customer.childName || "----"}
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>
                    <Typography
                      className={classes.typographyLight}
                      gutterBottom
                      variant="body1"
                    >
                      Child Birthday
                    </Typography>
                  </th>
                  <th className={classes.tablePadding}>
                    <div style={{}}>
                      {customer.customer.childBirthday
                        ? format(
                            new Date(customer.customer.childBirthday),
                            "dd LLLL yyyy"
                          )
                        : "----"}
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>
                    <Typography
                      className={classes.typographyLight}
                      gutterBottom
                      variant="body1"
                    >
                      Instagram Account
                    </Typography>
                  </th>
                  <th className={classes.tablePadding}>
                    <div
                      onClick={() =>
                        window.open(
                          `https://instagram.com/${customer.customer.instagramAccount}`,
                          "_blank"
                        )
                      }
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#5469D4",
                      }}
                    >
                      {customer.customer.instagramAccount === null ||
                      customer.customer.instagramAccount.length === 0 ? (
                        "----"
                      ) : (
                        <div>
                          https://instagram.com/
                          {customer.customer.instagramAccount}
                        </div>
                      )}
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>
                    <Typography
                      className={classes.typographyLight}
                      gutterBottom
                      variant="body1"
                    >
                      Facebook Account
                    </Typography>
                  </th>
                  <th className={classes.tablePadding}>
                    <div
                      onClick={() =>
                        window.open(
                          `https://facebook.com/${customer.customer.facebookAccount}`,
                          "_blank"
                        )
                      }
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#5469D4",
                      }}
                    >
                      {customer.customer.facebookAccount === null ||
                      customer.customer.facebookAccount.length === 0 ? (
                        "----"
                      ) : (
                        <div>
                          https://facebook.com/
                          {customer.customer.facebookAccount}
                        </div>
                      )}
                    </div>
                  </th>
                </tr>
              </table>

              <table style={{ marginLeft: "40px" }}>
                <tr>
                  <th>
                    <Typography
                      className={classes.typographyLight}
                      gutterBottom
                      variant="body1"
                    >
                      Discount
                    </Typography>
                  </th>
                  <th className={classes.tablePadding}>
                    <div style={{ color: "grey" }}>
                      {customer.customer.couponApplied || "No coupon applied"}
                    </div>
                  </th>
                </tr>
                {!customer.customer.influencer && (
                  <>
                    <tr>
                      <th>
                        <Typography
                          className={classes.typographyLight}
                          gutterBottom
                          variant="body1"
                        >
                          Billing Method
                        </Typography>
                      </th>
                      <th className={classes.tablePadding}>
                        <div style={{}}>Charge specific payment method</div>
                      </th>
                    </tr>
                    <tr>
                      <th>
                        <Typography
                          className={classes.typographyLight}
                          gutterBottom
                          variant="body1"
                        >
                          Payment Method
                        </Typography>
                      </th>
                      <th className={classes.tablePadding}>
                        <div style={{}}>
                          {customer.stripeCustomer?.defaultSource || "----"}
                        </div>
                      </th>
                    </tr>
                  </>
                )}

               
                <tr>
                  <th>
                    <Typography
                      className={classes.typographyLight}
                      gutterBottom
                      variant="body1"
                    >
                      Shipping Address
                    </Typography>
                  </th>
                  <th className={classes.tablePadding}>
                    {!customer.customer.influencer &&
                    customer.stripeCustomer ? (
                      <div>
                        <Typography
                          className={classes.bold}
                          gutterBottom
                          variant="body1"
                        >
                          {`${customer.customer.apartment} ${customer.stripeCustomer?.shipping?.address?.line1} ${customer.stripeCustomer?.shipping?.address?.city} ${customer.stripeCustomer?.shipping?.address?.state} ${customer.stripeCustomer?.shipping?.address?.postal_code} ${customer.stripeCustomer?.shipping?.address?.country} `}
                        </Typography>
                      </div>
                    ) : (
                      <Typography
                        className={classes.bold}
                        gutterBottom
                        variant="body1"
                      >
                        {customer.customer.shippingAddress}
                      </Typography>
                    )}
                  </th>
                </tr>
                {/* <tr>
                  <th>
                    <Typography
                      className={classes.typographyLight}
                      gutterBottom
                      variant="body1"
                    >
                      Billing Address
                    </Typography>
                  </th>
                  <th className={classes.tablePadding}>
                    {!customer.customer.influencer &&
                    customer.stripeCustomer ? (
                      <div>
                        <Typography
                          className={classes.bold}
                          gutterBottom
                          variant="body1"
                        >
                          {`${customer.stripeCustomer?.shipping?.address?.postal_code} ${customer.stripeCustomer?.shipping?.address?.line1} ${customer.stripeCustomer?.shipping?.address?.line2} ${customer.stripeCustomer?.shipping?.address?.state} ${customer.stripeCustomer?.shipping?.address?.country}  `}
                        </Typography>
                      </div>
                    ) : (
                      <Typography
                        className={classes.bold}
                        gutterBottom
                        variant="body1"
                      >
                        {customer.customer.shippingAddress}
                      </Typography>
                    )}
                  </th>
                </tr> */}
                <tr>
                  <th>
                    <Typography
                      className={classes.typographyLight}
                      gutterBottom
                      variant="body1"
                    >
                      Phone Number
                    </Typography>
                  </th>
                  <th className={classes.tablePadding}>
                    <Typography
                      className={classes.bold}
                      gutterBottom
                      variant="body1"
                    >
                      {`${customer.customer?.phone}`}
                      <br />
                    </Typography>
                  </th>
                </tr>
                <tr>
                  <th>
                    <Typography
                      className={classes.typographyLight}
                      gutterBottom
                      variant="body1"
                    >
                      Order ID
                    </Typography>
                  </th>
                  <th className={classes.tablePadding}>
                    <Typography
                      className={classes.bold}
                      gutterBottom
                      variant="body1"
                    >
                      {`${
                        customer.customer?.orderID ||
                        customer.customer.influencer
                          ? `MCI-${customer.customer.id
                              .toString()
                              .padStart(4, "0")}`
                          : `MCC-${customer.customer.id
                              .toString()
                              .padStart(4, "0")}`
                      }`}
                      <br />
                    </Typography>
                  </th>
                </tr>
              </table>
            </div>
          </div>

          <br />

          {/* <div className="padding mt-8">
            <Typography className={classes.bold} gutterBottom variant="h6">
              Most recent shipment
            </Typography>
            <table>
              <tr>
                <th>
                  <Typography
                    className={classes.typographyLight}
                    gutterBottom
                    variant="body1"
                  >
                    Tracking Link
                  </Typography>
                </th>
                <th className={classes.tablePadding}>
                  <Typography
                    className={classes.bold}
                    gutterBottom
                    variant="body1"
                  >
                    <div
                      onClick={() =>
                        window.open(
                          `${customer.customer.tracking_link}`,
                          "_blank"
                        )
                      }
                      style={{
                        // display: "flex",
                        // flexDirection: "row",
                        // alignItems: "center",
                        cursor: "pointer",
                        color: "#5469D4",
                      }}
                    >
                      {customer.customer.tracking_link === null ||
                      customer.customer.tracking_link.length === 0 ? (
                        "----"
                      ) : (
                        <div>{customer.customer.tracking_link}</div>
                      )}
                    </div>
                  </Typography>
                </th>
              </tr>
              <tr>
                <th>
                  <Typography
                    className={classes.typographyLight}
                    gutterBottom
                    variant="body1"
                  >
                    Date shipped
                  </Typography>
                </th>
                <th className={classes.tablePadding}>
                  <Typography
                    className={classes.bold}
                    gutterBottom
                    variant="body1"
                  >
                    {customer.customer.tracking_link
                      ? format(
                          new Date(customer.customer.next_box_date).setMonth(
                            new Date(
                              customer.customer.next_box_date
                            ).getMonth() - 1
                          ),
                          "dd LLLL yyyy"
                        ) || "----"
                      : "----"}
                    <br />
                  </Typography>
                </th>
              </tr>
              <tr>
                <th>
                  <Typography
                    className={classes.typographyLight}
                    gutterBottom
                    variant="body1"
                  >
                    Product(s) shipped
                  </Typography>
                </th>
                <th className={classes.tablePadding}>
                  <Typography
                    className={classes.bold}
                    gutterBottom
                    variant="body1"
                  >
                    ----
                  </Typography>
                </th>
              </tr>
            </table>
          </div> */}

          {/* {auth.authenticated && auth.user!.isAdmin && (
            <div className="mt-10 relative">
              <br />
              <div className="padding">
                <Typography className={classes.bold} variant="h6">
                  Days Until Next Box
                </Typography>
              </div>
              <Divider variant="middle" />
              <br />

              <div className="row">
                <div
                  style={{
                    zIndex: 0,
                    display:
                      getNextBoxDay() === 0 ||
                      getNextBoxDay() === 1 ||
                      showBoxToday
                        ? "flex"
                        : "none",
                  }}
                  className="boxes column center padding"
                >
                  <Typography
                    className={classes.bold}
                    gutterBottom
                    variant="h6"
                  >
                    Box To Send Today
                  </Typography>
                  <br />
                  <ProductBoxList
                    onItemSelected={(box: Box) => {
                      setBoxType(box);
                    }}
                  />
                  <input
                    onChange={(e) => setTrackingLink(e.target.value)}
                    style={{
                      marginTop: "10px",
                      paddingTop: "10px",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                      textAlign: "center",
                      paddingBottom: "10px",
                      border: "0px",
                      borderRadius: "12px",
                    }}
                    placeholder="Tracking number"
                    type="text"
                  />
                  <br />
                  <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
                    Next Box Date:{" "}
                    {nextBoxDate
                      ? format(new Date(nextBoxDate), "dd LLLL yyyy")
                      : "----"}
                  </div>
                  <Button
                    onClick={() =>
                      setShowNextBoxDatePicker(!showNextBoxDatePicker)
                    }
                    disableElevation={true}
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: 50,
                      marginBottom: "30px",
                      zIndex: -1,
                    }}
                  >
                    {showNextBoxDatePicker
                      ? "Hide date picker"
                      : " Select next box date"}
                  </Button>
                  <Button
                    ref={anchorRef2}
                    onClick={handleToggle}
                    disableElevation={true}
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: 50,
                      zIndex: -1,
                    }}
                  >
                    Confirm
                  </Button>
                  <Popper
                    open={open}
                    anchorEl={anchorRef2.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem
                                onClick={(e) => {
                                  confirmShipment();
                                  handleClose(e);
                                }}
                              >
                                <div
                                  className="row"
                                  style={{
                                    paddingTop: "10px",
                                    paddingBottom: "5px",
                                  }}
                                >
                                  <div className="padding">
                                    Confirm Shipment
                                  </div>
                                </div>
                              </MenuItem>
                              <Divider variant="middle" />
                              {
                                <MenuItem
                                  onClick={(e) => {
                                    handleClose(e);
                                  }}
                                >
                                  <div
                                    className="row"
                                    style={{
                                      color: "#f00",
                                      paddingTop: "10px",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    <div className="padding">Cancel</div>
                                  </div>
                                </MenuItem>
                              }
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>

                <div
                  style={{
                    opacity: showBoxToday && showNextBoxDatePicker ? "1" : "0",
                    width:
                      showBoxToday && showNextBoxDatePicker ? "320px" : "0px",
                    height:
                      showBoxToday && showNextBoxDatePicker ? "320px" : "0px",
                    transition: "all 0.2s ease",
                    color: "black",
                  }}
                >
                  <DayPicker
                    className="create-input"
                    selectedDays={
                      new Date(nextBoxDate || customer.customer.next_box_date)
                    }
                    onDayClick={(day: any) => {
                      setNextBoxDate(day);
                    }}
                  />
                </div>

                <div
                  style={{
                    height: "min-content",
                    backgroundColor: "#ffcc00",
                    alignItems: "center",
                    borderRadius: "12px",
                    // marginTop: "40px",
                    paddingTop: "10px",
                    display:
                      getNextBoxDay() === 0 || getNextBoxDay() === 1
                        ? "none"
                        : "flex",
                    paddingBottom: "20px",
                    paddingLeft: "25px",
                    paddingRight: "25px",
                  }}
                  className="column center padding"
                >
                  <Typography
                    className={classes.bold}
                    gutterBottom
                    variant="h6"
                  >
                    Next box in {getNextBoxDay()} days
                  </Typography>
                  <br />
                  <Button
                    onClick={() => setShowBoxToday(!showBoxToday)}
                    disableElevation={true}
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: "#fff", borderRadius: 50 }}
                  >
                    {showBoxToday
                      ? "Hide Box To Send Today"
                      : "Send Box out of order"}
                  </Button>
                </div>
              </div>
              <br />
            </div>
          )} */}

          <Divider variant="middle" />
          <br />

          <div style={{ marginTop: "20px" }} className="padding">
            {shipmentYears.sort((a,b)=>(parseInt(a ?? "0") - parseInt(b ?? "0"))).map((year) => {
              return (
                <>
                  <Typography
                    className={classes.bold}
                    gutterBottom
                    variant="h5"
                  >
                    {year}
                  </Typography>

                  <div
                    style={{
                      display: "grid",
                      width: "100%",
                      textAlign: "center",
                      columnGap: "50px",
                      gridTemplateColumns: "repeat(7, 1fr)",
                      // gridTemplateRows: "repeat(7, 1fr)",
                      gridColumnGap: "0px",
                      gridRowGap: "0px",
                    }}
                  >
                    {shipments
                      .filter(
                        (shipment) =>
                          format(new Date(shipment.date_shipped), "yyyy") ===
                          year
                      )
                      .sort(
                        (a, b) =>
                          new Date(a.date_shipped).getMonth() -
                          new Date(b.date_shipped).getMonth()
                      )
                      .map((item) => {
                        return <ShipmentItem item={item} />;
                      })}
                  </div>
                </>
              );
            })}
          </div>

          <NestedGrid />

          <Divider variant="middle" />
          <br />
          <br />
          <br />

          {auth.authenticated && auth.user!.isAdmin && (
            <div>
              <div className="padding mb-6">
                <Typography className={classes.bold} variant="h5">
                  Add custom shipment
                </Typography>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className="row">
                  <div
                    style={{
                      zIndex: 0,
                      display:
                        getNextBoxDay() === 0 ||
                        getNextBoxDay() === 1 ||
                        showBoxToday
                          ? "flex"
                          : "flex",
                    }}
                    className="boxes column center padding"
                  >
                    <br />
                    <ProductBoxList
                      onItemSelected={(box: Box) => {
                        setBoxType(box);
                      }}
                    />
                    <input
                      onChange={(e) => setTrackingLink(e.target.value)}
                      style={{
                        marginTop: "10px",
                        paddingTop: "10px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        textAlign: "center",
                        paddingBottom: "10px",
                        border: "0px",
                        borderRadius: "12px",
                      }}
                      className="text-black"
                      placeholder="Tracking number"
                      type="text"
                    />
                    <br />
                    <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
                      Date Shipped:{" "}
                      {format(new Date(nextBoxDate), "dd LLLL yyyy") || "----"}
                    </div>
                    <Button
                      onClick={() =>
                        setShowNextBoxDatePicker(!showNextBoxDatePicker)
                      }
                      disableElevation={true}
                      type="submit"
                      variant="contained"
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: 50,
                        marginBottom: "30px",
                        zIndex: -1,
                      }}
                    >
                      {showNextBoxDatePicker
                        ? "Hide date picker"
                        : " Select date shipped"}
                    </Button>
                    <Button
                      ref={anchorRef}
                      onClick={handleToggle}
                      disableElevation={true}
                      type="submit"
                      variant="contained"
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: 50,
                        zIndex: -1,
                      }}
                    >
                      Confirm
                    </Button>
                    <Popper
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom",
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList
                                autoFocusItem={open}
                                id="menu-list-grow"
                                onKeyDown={handleListKeyDown}
                              >
                                <MenuItem
                                  onClick={(e) => {
                                    confirmCustomShipment();
                                    handleClose(e);
                                  }}
                                >
                                  <div
                                    className="row"
                                    style={{
                                      paddingTop: "10px",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    <div className="padding">
                                      Confirm Shipment
                                    </div>
                                  </div>
                                </MenuItem>
                                <Divider variant="middle" />
                                {
                                  <MenuItem
                                    onClick={(e) => {
                                      handleClose(e);
                                    }}
                                  >
                                    <div
                                      className="row"
                                      style={{
                                        color: "#f00",
                                        paddingTop: "10px",
                                        paddingBottom: "5px",
                                      }}
                                    >
                                      <div className="padding">Cancel</div>
                                    </div>
                                  </MenuItem>
                                }
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                </div>
                <div
                  style={{
                    opacity: showNextBoxDatePicker ? "1" : "0",
                    width: showNextBoxDatePicker ? "320px" : "0px",
                    height: showNextBoxDatePicker ? "320px" : "0px",
                    transition: "all 0.2s ease",
                    color: "black",
                  }}
                >
                  <DayPicker
                    className="create-input"
                    selectedDays={
                      new Date(nextBoxDate || customer.customer.next_box_date)
                    }
                    onDayClick={(day: any) => {
                      setNextBoxDate(day);
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {!customer.customer.influencer && (
            <>
              <div className="padding">
                <SubscriptionPricingTable
                  subscriptions={
                    customer.customer.isAmazon
                      ? amazonCharges?.map((subscription: AmazonCharge) => ({
                          id: subscription.charge_id,
                          priceId: subscription.charge_permission_id,
                          duration: "Month",
                          quantity: "1",
                          price: subscription.amount,
                        }))
                      : customer.stripeCustomer?.subscriptions?.data?.map(
                          (subscription: any) => ({
                            id: subscription.id,
                            priceId: subscription.items?.data[0]?.plan?.id,
                            quantity: subscription.quantity,
                            duration:
                              customer.customer.subscriptionType === "month-12"
                                ? "12 months"
                                : customer.customer.subscriptionType ===
                                  "month-9"
                                ? "9 months"
                                : "Month",
                            price:
                              customer.customer.subscriptionType === "month-12"
                                ? "$374.74"
                                : customer.customer.subscriptionType ===
                                  "month-9"
                                ? "$332.80"
                                : "$41.6",
                          })
                        ) || []
                  }
                />
              </div>
            </>
          )}
        </div>
      )}
    </main>
  );
};

const LoadDiv = styled.div`
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export default SubscriptionDetails;
