/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// misc
import {
  setSearchInput,
  setActiveSidebar,
  setSelectedCustomerTab,
} from "../../../store/modules/misc.reducer";
import { RootState } from "../../../store/root";

import SearchBar from "../../search-bar";

const tabs: string[] = ["live", "paused", "cancelled", "abandoned"];

interface IndicatorPosition {
  left: number;
  width: number;
}

const CustomersHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { activeSidebar, selectedCustomerTab, searchInput } = useSelector(
    (state: RootState) => state.misc
  );
  const tabElements = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [indicatorPosition, setIndicatorPosition] = useState<IndicatorPosition>(
    {
      left: 8,
      width: 60.6,
    }
  );

  useEffect(() => {
    tabElements.current[selectedCustomerTab]?.click();
  }, []);

  const setSelectedTab = (item: string) => {
    dispatch(setSelectedCustomerTab(item));
  };

  const setSidebar = (sidebar: string) => {
    dispatch(setActiveSidebar(sidebar));
  };

  return (
    <header className="py-5">
      <div className="flex items-center">
        <h2 className="pr-6 text-[#FFCC00] text-lg">Customers</h2>
        <SearchBar
          searchInput={searchInput}
          setSearchInput={(value: string) => dispatch(setSearchInput(value))}
        />
        <Button
          disableElevation={true}
          variant="contained"
          style={{
            marginLeft: "auto",
            backgroundColor: "#ffc000",
            color: "var(--darker-charcoal)",
            borderRadius: "8px",
            fontWeight: 600,
            display:
              activeSidebar === "influencers" || activeSidebar === "templates"
                ? "none"
                : "flex",
          }}
          onClick={() => navigate("/create")}
        >
          Create
        </Button>
      </div>
      <div className="mt-4 relative rounded-full flex gap-3 items-center bg-[color:var(--dark-charcoal)]">
        <div
          style={{
            width: indicatorPosition.width + "px",
            left: indicatorPosition.left + "px",
          }}
          className="capitalize z-1 absolute transition-all duration-150 inset-2 px-4 py-2 rounded-full  cursor-pointer font-bold bg-[color:var(--charcoal)] self-stretch"
        ></div>
        {tabs.map((item, index) => (
          <div
            ref={(element: any) => (tabElements.current[item] = element)}
            onClick={(evt) => {
              // alert("clicked");
              const targetsBoxModel = evt.currentTarget.getBoundingClientRect();
              setIndicatorPosition({
                left:
                  targetsBoxModel.x -
                  evt.currentTarget.parentElement!.getBoundingClientRect().x,
                width: targetsBoxModel.width,
              });
              if (tabs.includes(item)) {
                setSidebar("customers");
                setSelectedTab(item);
              } else {
                setSidebar(item);
              }
            }}
            className={`capitalize relative z-2 m-2 px-4  transition-all delay-150 py-2 rounded-full  cursor-pointer font-semibold ${
              selectedCustomerTab === item
                ? " text-[color:var(--orange)]"
                : "text-white"
            }`}
            key={index}
          >
            {item}
          </div>
        ))}
      </div>
    </header>
  );
};

export default CustomersHeader;
