import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Customer, Influencer } from "../../@types";
import { format } from "date-fns";
import { BeatLoader } from "react-spinners";
import styled from "styled-components";
import UsersIcon from "../SVGIcons/UsersIcon";
import { useNavigate } from "react-router-dom";
import api from "../../network/api";

interface HeadCell {
  disablePadding: boolean;
  id: keyof Customer;
  label: string;
  numeric: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const headCells: HeadCell[] = [
  { id: "name", numeric: false, disablePadding: false, label: "Name" },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  {
    id: "subscriptionType",
    numeric: false,
    disablePadding: false,
    label: "Plan",
  },
  {
    id: "subscriptionStart",
    numeric: false,
    disablePadding: false,
    label: "Subscription Start",
  },
  {
    id: "daysUntilNextBox",
    numeric: false,
    disablePadding: false,
    label: "Days Until Next Box",
  },
  { id: "received", numeric: false, disablePadding: false, label: "Received" },
];

// function InfluencerTableHead() {
//   const classes = useStyles();

//   return (
//     <TableHead>
//       <TableRow className={classes.tableRow}>
//         {headCells.map((headCell) => (
//           <TableCell
//             key={headCell.id}
//             align={headCell.numeric ? "right" : "left"}
//             padding={headCell.disablePadding ? "none" : "normal"}
//             className={classes.headerCell}
//           >
//             <TableSortLabel className={classes.cell}>
//               {headCell.label}
//             </TableSortLabel>
//           </TableCell>
//         ))}
//       </TableRow>
//     </TableHead>
//   );
// }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      // minHeight: "39vh",
      marginBottom: theme.spacing(2),
      background: "var(--charcoal)",
      boxShadow: "none",
    },
    table: {
      width: "100%",
      // background: "#000",
      color: "#fff",
      borderSpacing: "0 0px",
      borderCollapse: "collapse",
      // borderRadius: "15px",
      overflow: "hidden",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    tableRow: {
      // background: "#E9F4F9",
      transition: "all 0.2s all",
      opacity: "0.95",
      "&:nth-child(odd)": {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      },
      "&:hover": {
        cursor: "pointer",
        opacity: "1",
        // background: "#E9F4F9",
      },
    },
    cell: {
      color: "#fff",
      fontWeight: 500,
    },
    headerCell: {
      color: "#000",
      fontWeight: "bold",
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

interface Props {
  influencers: Influencer[];
  loading?: boolean;
  hideHeader?: boolean;
}

const InfluencerDashboardTable: React.FC<Props> = ({
  influencers,
  loading,
  hideHeader,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const onUserSelect = (customerId: number, email: string) => {
    api.post("/influencer/read", {
      email: email,
      is_read: true,
    });
    navigate(`/influencers/${email}`);
  };

  // const dashboardHeaders = ["Name", "Email", "Date"];

  return (
    <div className={classes.root}>
      <div
        className="flex items-center mb-[20px] text-xl px-4"
        style={{
          display: influencers.length > 0 ? "flex" : "none",
          fontSize: "1.3rem",
          // marginLeft: "40px",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            height: "20px",
            width: "20px",
            marginRight: "10px",
            transform: "translateY(-2px)",
          }}
        >
          <UsersIcon />
        </div>
        {influencers.length}&nbsp;
        <span>influencer(s)</span>
        {/* {influencers.length === 1 ? " influencer" : " influencers"} */}
      </div>
      <Paper className={classes.paper}>
        <TableContainer>
          {loading ? (
            <LoadDiv>
              <BeatLoader color="#ffcc00" />
            </LoadDiv>
          ) : (
            <div>
              {influencers.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "1.5rem",
                    color: "white",
                  }}
                >
                  No Influencer on this list
                </div>
              ) : (
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size="medium"
                  aria-label="enhanced table"
                >
                  <TableBody>
                    {influencers.map((influencer) => (
                      <TableRow
                        key={influencer.id}
                        aria-checked={true}
                        // onClick={() => onCustomerSelected(influencer.id)}
                        onClick={() =>
                          onUserSelect(influencer.id, influencer.email)
                        }
                        selected={false}
                        className={classes.tableRow}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.cell}
                        >
                          {`${influencer.first_name} ${influencer.last_name}`}
                        </TableCell>
                        <TableCell align="left" className={classes.cell}>
                          {influencer.email}
                        </TableCell>

                        <TableCell align="left" className={classes.cell}>
                          {influencer.created_at
                            ? format(
                                new Date(influencer.created_at),
                                "dd-MM-yyyy"
                              )
                            : "-----"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </div>
          )}
        </TableContainer>
      </Paper>
    </div>
  );
};

const LoadDiv = styled.div`
  height: 50vh;
  width: 100%;
  margin-top: "400px";
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default InfluencerDashboardTable;
