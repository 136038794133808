import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Influencer } from "../../@types";
import { setActiveSidebar } from "../../store/modules/misc.reducer";
import CircularLoading from "../Animations/Loading";
import InfluencerDashboardTable from "../InfluencerDashboardTable";

type Props = {
  influencers: Influencer[];
  originalInfluencers: Influencer[];
  loading: boolean;
};

function Selection({ influencers, originalInfluencers, loading }: Props) {
  const dispatch = useDispatch();

  const gotoLookup = () => {
    dispatch(setActiveSidebar("lookup"));
  };

  return (
    <StyledDiv>
      <div
        className={`flex justify-center absolute items-center w-[80vw] flex-col ${
          loading ? "opacity-1 translateY(30vh)" : "opacity-0 translateY(20vh)"
        } transition-all duration-300 ease-in-out`}
        // style={{
        //   display: "flex",
        //   justifyContent: "center",
        //   position: "absolute",
        //   alignItems: "center",
        //   width: "80vw",
        //   flexDirection: "column",
        //   opacity: loading ? "1" : "0",
        //   transition: "all 0.3s ease",
        //   transform: loading ? "translateY(30vh)" : "translateY(20vh)",
        // }}
      >
        <CircularLoading />
      </div>
      {influencers.length > 0 ? (
        <div
          className={`transition-all duration-300 ease-in-out ${
            loading
              ? "opacity-0 translateY(90px)"
              : "opacity-100 translateY(0px)"
          }`}
        >
          <InfluencerDashboardTable
            influencers={influencers}
            loading={loading}
          />
        </div>
      ) : (
        <div
          style={{ opacity: loading ? "0" : "1" }}
          className="no-accounts h-[39vh]"
        >
          <h2>No accounts selected yet</h2>
          <button onClick={gotoLookup}>Add Accounts</button>
        </div>
      )}
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  width: 100%;
  & > .accounts {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  & > .no-accounts {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 39vh;
    & > button {
      background: #ffcc00;
      color: #000;
      border: none;
      padding: 1rem 2rem;
      border-radius: 4px;
      cursor: pointer;
      font-weight: bold;
    }
  }
`;
// const LoadDiv = styled.div`
//   margin-top: 8rem;
//   height: 80vh;
//   display: flex;
//   align-self: center;
//   justify-content: center;
// `;

export default Selection;
