import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";

// import SideBar from "../../sidebar";
import SideBar from "../SideBar";

interface AppProps {
  header?: React.ReactNode;
  headerWrapperClass?: string;
}

const DashboardLayout: FC<AppProps> = ({ header, headerWrapperClass }) => {
  // const [searchInput, setSearchInput] = useState<string>("");
  // const [searchData, setSearchData] = React.useState<any[]>([]);
  // const { activeSidebar, activePage, selectedCustomerTab } = useSelector(
  //   (state: RootState) => state.misc
  // );
  // const [abandoned, setAbandoned] = React.useState<Customer[]>([]);
  // const [customers, setCustomers] = React.useState<Customer[]>([]);
  // const [influencers, setInfluencers] = React.useState<Influencer[]>([]);

  return (
    <>
      <Helmet title="Dashboard" />

      <div className="flex gap-10">
        <SideBar />
        <main className="flex-1 h-screen overflow-y-auto pr-4">
          {header && (
            <div
              className={`sticky top-0 z-[10] w-full  px-6 mt-6 ${headerWrapperClass}`}
            >
              {header}
            </div>
          )}
          {/* <DashboardHeader
            wrapperClass="sticky top-0 z-[10] w-full bg-black"
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            documents={
              searchInput
                ? searchData
                : activeSidebar === "customers"
                ? customers
                : activeSidebar === "abandoned"
                ? abandoned
                : activeSidebar === "influencers"
                ? influencers
                : []
            }
          /> */}
          {/* {renderRoutes(route.routes)} */}
          <div className="relative z-1 text-yellow-400">
            <Outlet />
          </div>
        </main>
      </div>
    </>
  );
};

export default DashboardLayout;
