import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Image } from 'semantic-ui-react';
import Typography from '@material-ui/core/Typography';

import logo from '../assets/images/mochi-logo.png';
import { TrackItem } from '../@types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            justifyContent: 'flex-start',
        },
        typographySmall: {
            fontSize: 15,
        },
        bold: {
            fontWeight: 'bold',
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: 'center',
            color: '#000',
        },
    })
);

interface Props {
    trackingItems?: TrackItem[];
}

const NestedGrid: React.FC<Props> = ({ trackingItems = [] }) => {
    const classes = useStyles();

    function FormRow() {
        return (
            <React.Fragment>
                {trackingItems.map((item, index) => (
                    <Grid item xs={4}>
                        <Paper elevation={0} className={classes.paper} key={index}>
                            <div>
                                <Typography className={classes.bold} gutterBottom variant="h5">
                                    Jan
                                </Typography>
                            </div>
                            <Image className="logo-small" src={logo} />
                            <Typography
                                className={classes.typographySmall}
                                gutterBottom
                                variant="h5"
                            >
                                MCL 2
                            </Typography>
                            <Typography
                                className={classes.typographySmall}
                                gutterBottom
                                variant="h5"
                            >
                                07/07/2021
                            </Typography>
                            <Typography
                                className={classes.typographySmall}
                                gutterBottom
                                variant="h5"
                            >
                                *Tracking Number*
                            </Typography>
                        </Paper>
                    </Grid>
                ))}
            </React.Fragment>
        );
    }

    return (
        <div>
            <Grid>
                <Grid container item xl={10} spacing={5}>
                    <FormRow />
                </Grid>
            </Grid>
        </div>
    );
};

export default NestedGrid;
