import { capitalCase } from "change-case";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import { Influencer } from "../../@types";
import CorrespondenceTable from "../../components/CorrespondenceTable";
import Ordered from "../../components/dashboard/Ordered";
import Selection from "../../components/dashboard/selection";
import EmailTable from "../../components/Emailtable";
import InfluencersHeader from "../../components/layout/Headers/Influencers";
import { InfluencerRequest } from "../../network/requests/influencer.request";

const Influencers: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [originalInfluencers, setOriginalInfluencers] = React.useState<
    Influencer[]
  >([]);
  const [influencers, setInfluencers] = React.useState<Influencer[]>([]);

  React.useEffect(() => {
    const getInfluencers = async () => {
      const influencers = await InfluencerRequest.FetchAll();
      // console.log(influencers);
      setOriginalInfluencers(influencers);
      setInfluencers(influencers);
      setLoading(false);
    };
    getInfluencers();
  }, []);

  const TAB_COMPONENTS: { [key: string]: React.ReactNode } = {
    "All Accounts": (
      <Selection
        influencers={influencers}
        originalInfluencers={originalInfluencers}
        loading={loading}
      />
    ),
    "1st Email": (
      <EmailTable influencers={influencers} loading={loading} emailcount={1} />
    ),
    "2nd Email": (
      <EmailTable influencers={influencers} loading={loading} emailcount={2} />
    ),
    Correspondence: <CorrespondenceTable />,
    Ordered: (
      <Ordered
        influencers={influencers}
        originalInfluencers={originalInfluencers}
        loading={loading}
      />
    ),
  };

  const selectedTab: string =
    location.search.replace("?tab=", "").replaceAll("-", " ") || "All Accounts";

  return (
    <>
      <InfluencersHeader />
      <section className="mx-4">
        <div>
          {Object.keys(TAB_COMPONENTS).map(
            (tab: string, index: number, arr: string[]) => (
              <button
                onClick={() =>
                  navigate(`/influencers?tab=${tab.replaceAll(" ", "-")}`)
                }
                className={`py-3 px-4 relative rounded-t-lg font-semibold bg-[color:var(--darker-charcoal)] text-gray-400 hover:text-gray-300 z-[${
                  arr.length - index
                }]  ${index > 0 && "pl-10 -ml-5"}  ${
                  selectedTab === tab && "bg-[#1f2733] text-gray-200"
                }`}
                key={tab + index}
              >
                {capitalCase(tab)}
              </button>
            )
          )}
        </div>
        <div className="bg-[#1f2733] rounded-b-lg rounded-r-lg overflow-hidden py-6">
          {TAB_COMPONENTS[selectedTab]}
        </div>
      </section>
    </>
  );
};

export default Influencers;
