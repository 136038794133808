/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useMemo } from "react";
import { format } from "date-fns";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { TableHeader } from "semantic-ui-react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import api from "../network/api";
import { Customer } from "../@types";
import { renderSubscriptionType } from "../shared/functions";


interface HeadCell {
  disablePadding: boolean;
  id: keyof Customer;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: "name", numeric: false, disablePadding: false, label: "Name" },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  {
    id: "subscriptionType",
    numeric: false,
    disablePadding: false,
    label: "Plan",
  },
  {
    id: "subscriptionStart",
    numeric: false,
    disablePadding: false,
    label: "Subscription Start",
  },
  {
    id: "daysUntilNextBox",
    numeric: false,
    disablePadding: false,
    label: "Days Until Next Box",
  },
  { id: "received", numeric: false, disablePadding: false, label: "Received" },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function EnhancedTableHead() {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow className={classes.tableRow}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            className={classes.headerCell}
          >
            <TableSortLabel className={classes.cell}>
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      background: "var(--dark-charcoal)",
    },
    table: {
      width: "100%",
      background: "var(--charcoal)",
      color: "#fff",
      borderSpacing: "0 0px",
      borderCollapse: "separate",
      // borderRadius: "15px",
      overflow: "hidden",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    tableRow: {
      minWidth: "100%",
      background: "#E9F4F9",
      color: "white",
      transition: "all 0.2s all",
      opacity: "0.95",
      "&:hover": {
        cursor: "pointer",
        transition: "all 0.2s all",
        background: "#E9F4F9",
        opacity: "1",
      },
    },
    tableRowUrgent: {
      minWidth: "100%",
      background: "#ffcc00",
      color: "white",
      transition: "all 1s all",
      borderRadius: "20px",
      opacity: "0.95",
      paddingInline: "20px",
      paddingBlock: "20px",
      "&:hover": {
        cursor: "pointer",
        background: "#ffcc00",
        transition: "all 1s all",

        opacity: "1",
      },
    },
    cell: {
      color: "#000",
      // fontWeight: "bold",
      paddingInline: "20px",
      paddingBlock: "20px",
    },
    headerCell: {
      color: "#000",
      fontWeight: "bold",
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

interface Props {
  customers: Customer[];
  urgentCustomers: Customer[];
  loading?: boolean;
  hideHeader?: boolean;
  activeSidebar: string;
  searchInput: string;
}

const dashboardHeaders = [
  "Name",
  "Email",
  "Country",
  "Subscription Type",
  "Date Subscribed",
  "Last Box Shipped",
  "Date Shipped",
  "Payment method",
];

const DashboardTable: React.FC<Props> = ({
  loading,
  customers,
  hideHeader,
  searchInput,
  activeSidebar,
  urgentCustomers,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  // const { searchInput: filter } = useSelector((state: RootState) => state.misc);

  const onCustomerSelected = (customerId: number, email: string) => {
    api.post("/customers/read", {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      },

      email: email,
      isRead: true,
    });

    navigate(`/customers/${customerId}`);
  };

  const onUserSelect = (customerId: number, email: string) => {
    api.post("/customers/read", {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      },
      email: email,
      isRead: true,
    });
    navigate(`/influencer/${email}`);
  };
  /**
   * new customers data set
   */
  const newCustomers = useMemo(() => {
    console.log(customers);
    return (
      customers?.filter(
        (customer) => parseInt(customer.boxesShipped || "0") < 1
      ) ?? []
    );
  }, [customers]);

  // todo: filter data by the filter
  // if(filter.trim().length > 0) {

  // }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          {loading ? (
            <LoadDiv>
              <BeatLoader color="#ffcc00" />
            </LoadDiv>
          ) : (
            <>
              <div className="bg-[color:var(--dark-charcoal)]">
                <p className="font-bold text-lg text-white p-4 flex-inline bg-[color:var(--charcoal)] w-fit rounded-t-lg">
                  Ready to send next box
                  {urgentCustomers.length > 0 && (
                    <>
                      :&nbsp;{" "}
                      <span style={{ color: "#ffcc00" }}>
                        {urgentCustomers.length}
                      </span>
                    </>
                  )}
                </p>
              </div>
              <div>
                <div>
                  <table
                    className={`${classes.table} rounded-lg rounded-tl-none`}
                  >
                    {urgentCustomers.length > 0 && (
                      <TableHeader>
                        <TableRow>
                          {dashboardHeaders.map((header) => (
                            <TableCell style={{ fontWeight: "bold" }}>
                              {header}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHeader>
                    )}
                    {urgentCustomers.map((customer) => (
                      <tr
                        key={customer.id}
                        aria-checked={true}
                        onClick={() =>
                          activeSidebar === "abandoned"
                            ? onUserSelect(customer.id, customer.email)
                            : onCustomerSelected(customer.id, customer.email)
                        }
                        className={classes.tableRowUrgent}
                      >
                        <td align="left" className={classes.cell}>
                          {customer.name}
                        </td>
                        <td align="left" className={classes.cell}>
                          {customer.email}
                        </td>
                        <td align="left" className={classes.cell}>
                          {customer.country ?? "---"}
                        </td>
                        <td align="left" className={classes.cell}>
                          {renderSubscriptionType(customer.subscriptionType)}
                        </td>
                        <td align="left" className={classes.cell}>
                          {customer.subscriptionStart
                            ? format(
                                new Date(customer.subscriptionStart),
                                "dd-MM-yyyy"
                              )
                            : "-----"}
                        </td>

                        <td align="left" className={classes.cell}>
                          {customer.box_type ?? "---"}
                        </td>

                        <td align="left" className={classes.cell}>
                          {customer.date_shipped ?? "---"}
                        </td>

                        <td
                          style={{ display: "flex", flexDirection: "row" }}
                          align="left"
                          className={classes.cell}
                        >
                          <div
                            style={{
                              marginLeft: "10px",
                              paddingInline: "13px",
                              paddingBlock: "2px",
                              display: "flex",
                              flexDirection: "row",
                              backgroundColor: "white",
                              borderRadius: "5px",
                              fontWeight: "normal",
                              color: "#3b7bbf",
                              width: "80px",
                              textAlign: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              alt="paypal"
                              height="20px"
                              width="20px"
                              style={{ marginRight: "5px" }}
                              src={
                                customer.isPayPal
                                  ? "../../assets/images/paypal.png"
                                  : customer.isAmazon
                                  ? "../../assets/images/amazon.png"
                                  : customer.walletName === "googlePay"
                                  ? "../../assets/images/googlelogo.png"
                                  : "../../assets/images/stripelogo.png"
                              }
                            />
                            {customer.isPayPal
                              ? "Paypal"
                              : customer.isAmazon
                              ? "Amazon"
                              : customer.walletName === "googlePay"
                              ? "Pay"
                              : "Stripe"}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </table>
                  <div className="bg-[color:var(--dark-charcoal)] mt-12">
                    <p className="font-bold text-lg text-white p-4 flex-inline bg-[color:var(--charcoal)] w-fit rounded-t-lg">
                      New customers:&nbsp;{" "}
                      <span style={{ color: "#ffcc00" }}>
                        {newCustomers.length}
                      </span>
                    </p>
                  </div>
                  {newCustomers.length === 0 && (
                    <div className="bg-[color:var(--charcoal)] py-20 text-gray-400 text-center rounded-b-md text-xl">
                      No Data
                    </div>
                  )}
                  <table
                    className={`${classes.table} rounded-lg rounded-tl-none`}
                  >
                    {newCustomers.length > 0 && (
                      <TableHeader>
                        <TableRow>
                          {dashboardHeaders.map((header) => (
                            <TableCell style={{ fontWeight: "bold" }}>
                              {header}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHeader>
                    )}
                    {newCustomers.map((customer) => (
                      <tr
                        key={customer.id}
                        aria-checked={true}
                        onClick={() =>
                          activeSidebar === "abandoned"
                            ? onUserSelect(customer.id, customer.email)
                            : onCustomerSelected(customer.id, customer.email)
                        }
                        className={classes.tableRowUrgent}
                      >
                        <td align="left" className={classes.cell}>
                          {customer.name}
                        </td>
                        <td align="left" className={classes.cell}>
                          {customer.email}
                        </td>
                        <td align="left" className={classes.cell}>
                          {customer.country ?? "---"}
                        </td>

                        <td align="left" className={classes.cell}>
                          {renderSubscriptionType(customer.subscriptionType)}
                        </td>
                        <td align="left" className={classes.cell}>
                          {customer.subscriptionStart
                            ? format(
                                new Date(customer.subscriptionStart),
                                "dd-MM-yyyy"
                              )
                            : "-----"}
                        </td>

                        <td align="left" className={classes.cell}>
                          {customer.box_type ?? "---"}
                        </td>

                        <td align="left" className={classes.cell}>
                          {customer.date_shipped ?? "---"}
                        </td>

                        <td
                          style={{ display: "flex", flexDirection: "row" }}
                          align="left"
                          className={classes.cell}
                        >
                          <div
                            className="ml-5 flex rounded font-normal w-[80px] bg-white text-[#3b7bbf] text-center justify-center"
                            style={{
                              paddingInline: "13px",
                              paddingBlock: "2px",
                              display:
                                activeSidebar === "abandoned" ? "none" : "flex",
                            }}
                          >
                            <img
                              alt="paypal"
                              height="20px"
                              width="20px"
                              style={{ marginRight: "5px" }}
                              src={
                                customer.isPayPal
                                  ? "../../assets/images/paypal.png"
                                  : customer.isAmazon
                                  ? "../../assets/images/amazon.png"
                                  : customer.walletName === "googlePay"
                                  ? "../../assets/images/googlelogo.png"
                                  : "../../assets/images/stripelogo.png"
                              }
                            />
                            {customer.isPayPal
                              ? "Paypal"
                              : customer.isAmazon
                              ? "Amazon"
                              : customer.walletName === "googlePay"
                              ? "Pay"
                              : "Stripe"}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </table>
                  {/* <br
                    style={{
                      display:
                        customers.filter((customer) => !customer.isRead)
                          .length > 0
                          ? "flex"
                          : "none",
                    }}
                  /> */}
                  <div className="bg-[color:var(--dark-charcoal)] mt-12">
                    <p className="font-bold text-lg text-white p-4 flex-inline bg-[color:var(--charcoal)] w-fit rounded-t-lg">
                      {searchInput ? "Search results" : "All Customers"}:&nbsp;{" "}
                      <span style={{ color: "#ffcc00" }}>
                        {customers.length}
                      </span>
                    </p>
                  </div>
                  {/* <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                      marginLeft: "20px",
                      marginBottom: "10px",
                      display: customers.length > 0 ? "flex" : "none",
                    }}
                  >
                    {searchInput ? "Search results" : "All Customers"}:&nbsp;{" "}
                    <div style={{ color: "#ffcc00" }}>{customers.length}</div>
                  </p> */}
                  <table
                    className={`${classes.table} rounded-lg rounded-tl-none`}
                  >
                    {customers.length && (
                      <TableHeader>
                        <TableRow>
                          {dashboardHeaders.map((header) => (
                            <TableCell style={{ fontWeight: "bold" }}>
                              {header}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHeader>
                    )}
                    {customers.length === 0 && (
                      <div className="bg-[color:var(--charcoal)] py-20 text-gray-400 text-center rounded-b-md text-xl">
                        No Data
                      </div>
                    )}
                    <TableBody>
                      {customers.map((customer) => {
                        return (
                          <TableRow
                            key={customer.id}
                            aria-checked={true}
                            onClick={() =>
                              activeSidebar === "abandoned"
                                ? onUserSelect(customer.id, customer.email)
                                : onCustomerSelected(
                                    customer.id,
                                    customer.email
                                  )
                            }
                            selected={false}
                            className={classes.tableRow}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.cell}
                            >
                              {customer.name}
                            </TableCell>
                            <TableCell align="left" className={classes.cell}>
                              {customer.email}
                            </TableCell>
                            <TableCell align="left" className={classes.cell}>
                              {customer.country ?? "---"}
                            </TableCell>
                            <TableCell align="left" className={classes.cell}>
                              {renderSubscriptionType(
                                customer.subscriptionType
                              )}
                            </TableCell>
                            <TableCell align="left" className={classes.cell}>
                              {customer.subscriptionStart
                                ? format(
                                    new Date(customer.subscriptionStart),
                                    "dd-MM-yyyy"
                                  )
                                : "---"}
                            </TableCell>

                            <TableCell align="left" className={classes.cell}>
                              {customer.box_type ?? "---"}
                            </TableCell>
                            <TableCell align="left" className={classes.cell}>
                              {customer?.date_shipped &&
                              customer?.date_shipped?.length > 4
                                ? format(
                                    new Date(customer.date_shipped ?? ""),
                                    "dd-MM-yyyy"
                                  )
                                : "---"}
                            </TableCell>

                            <TableCell
                              style={{ display: "flex", flexDirection: "row" }}
                              align="left"
                              className={classes.cell}
                            >
                              <div
                                style={{
                                  marginLeft: "10px",
                                  paddingInline: "13px",
                                  paddingBlock: "2px",
                                  display:
                                    activeSidebar === "abandoned"
                                      ? "none"
                                      : "flex",
                                  flexDirection: "row",
                                  backgroundColor: "white",
                                  borderRadius: "5px",
                                  fontWeight: "normal",
                                  color: "#3b7bbf",
                                  width: "80px",
                                  textAlign: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  alt="paypal"
                                  height="20px"
                                  width="20px"
                                  style={{ marginRight: "5px" }}
                                  src={
                                    customer.isPayPal
                                      ? "../../assets/images/paypal.png"
                                      : customer.isAmazon
                                      ? "../../assets/images/amazon.png"
                                      : customer.walletName === "googlePay"
                                      ? "../../assets/images/googlelogo.png"
                                      : "../../assets/images/stripelogo.png"
                                  }
                                />
                                {customer.isPayPal
                                  ? "Paypal"
                                  : customer.isAmazon
                                  ? "Amazon"
                                  : customer.walletName === "googlePay"
                                  ? "Pay"
                                  : "Stripe"}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </table>
                </div>
              </div>
            </>
          )}
        </TableContainer>
      </Paper>
    </div>
  );
};

const LoadDiv = styled.div`
  height: 50vh;
  width: 100%;
  margin-top: "400px";
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default DashboardTable;
