import { Influencer } from "./../../@types";
import api from "../api";

export class InfluencerRequest {
  /**
   * fetches all influencers
   *
   * @returns {Promise<Customer[]>} - resolved or rejected promise object
   */
  static FetchAll(): Promise<Influencer[]> {
    return new Promise((resolve, reject) => {
      api
        .get<Influencer[]>("/influencers", {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }
}
