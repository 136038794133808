import React, { useState } from "react";
import { Button } from "@material-ui/core";

// misc
import SearchBar from "../../search-bar";
import { useDispatch } from "react-redux";
import { openNewTemplateModal } from "../../../store/modules/misc.reducer";

const TemplatesHeader = () => {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState<string>("");

  /**
   * toggles modal
   *
   * @returns {undefined}
   */
  function openModal() {
    dispatch(openNewTemplateModal());
  }

  return (
    <header className="flex justify-between items-center py-3">
      <h1 className="text-lg rounded text-[color:var(--orange)]">Templates</h1>
      <SearchBar
        // wrapperClass="!flex max-w-lg"
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      />
      <Button
        disableElevation={true}
        variant="contained"
        style={{
          // padding: "0.5rem 2rem",
          backgroundColor: "#ffc000",
          display: "flex",
          alignItems: "center",
          color: "var(--darker-charcoal)",
          borderRadius: "8px",
          fontWeight: 600,
        }}
        onClick={openModal}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 mr-1"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
            clipRule="evenodd"
          />
        </svg>
        <span>Add</span>
      </Button>
    </header>
  );
};

export default TemplatesHeader;
