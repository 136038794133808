import React, { FC, useState } from "react";

interface AppProps {
  content: string | any;
}

const ClipboardButton: FC<AppProps> = ({ content }) => {
  const [copying, setCopying] = useState<boolean>(false);

  function copyToClipboard() {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(content);
    }

    setCopying(true);
    setTimeout(() => {
      setCopying(false);
    }, 1300);
  }

  return (
    <button
      className="w-6 h-6 rounded overflow-hidden"
      onClick={() => copyToClipboard()}
    >
      <div
        className="w-12 flex transition-all duration-100"
        style={{ marginLeft: copying ? "-100%" : 0 }}
      >
        <div className="flex-1 flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 "
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
            />
          </svg>
        </div>
        <div className="text-xs bg-green-400 rounded flex items-center justify-center text-black flex-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 "
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
    </button>
  );
};

export default ClipboardButton;
