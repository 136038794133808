import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box } from "../@types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    paper: {
      marginRight: theme.spacing(2),
    },
    menuItem: {
      "&:hover": {
        fontWeight: "bold",
      },
    },
  })
);

type Props = {
  onItemSelected: Function;
};

function ProductBoxList({ onItemSelected }: Props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let [box, setBox] = useState<Box>();
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  let products = [
    { product_code: "MCL 1", product_name: "Computer Science" },
    { product_code: "MCL 2", product_name: "Neuroscience" },
    { product_code: "MCL 3", product_name: "Dinosaurs" },
    { product_code: "MCL 4", product_name: "Good habits" },
    { product_code: "MCL 5", product_name: "Words" },
    { product_code: "MCL 6", product_name: "Planets" },
    { product_code: "MCL 7", product_name: "Shapes" },
    { product_code: "MCL 8", product_name: "Insects and Bugs" },
    { product_code: "SKU1", product_name: "Mochi Screenless Coding Robotics Kit" },
    { product_code: "SKU2", product_name: "Mochi App Robotics Kit" },
    { product_code: "SKU3", product_name: "Learn Colors with Mochi" },
    { product_code: "SKU4", product_name: "Mochi Learns Numbers" },
    { product_code: "SKU5", product_name: "Learn Letters with Mochi" },
    { product_code: "SKU6", product_name: "Learn Shapes with Mochi" },
    { product_code: "SKU7", product_name: "Learn Animals with Mochi" },
    { product_code: "SKU8", product_name: "Mochi Learns Words" },
    { product_code: "SKU9", product_name: "Learn Fruits and Vegetables" },
    { product_code: "SKU10", product_name: "Learn Earth With Mochi" },
    { product_code: "SKU11", product_name: "Learn Time With Mochi" },
    { product_code: "SKU12", product_name: "Learn Biology With Mochi" },
    { product_code: "SKU13", product_name: "Learn Good Habits With Mochi" },
  ];

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <div>
        <Button
          style={{ backgroundColor: "white", borderRadius: 10 }}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          {box === undefined ? "Select Box" : `${box?.product_code} - ${box?.product_name}`}
          <KeyboardArrowDown />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    className={classes.menuItem}
                  >
                    {products.map((product) => {
                      return (
                        <MenuItem
                          onClick={(e) => {
                            handleClose(e);
                            setBox(product);
                            onItemSelected(product);
                          }}
                          className={classes.menuItem}
                        >
                          {product.product_code === "SKU2" ? "SKU1.3":product.product_code} - {product.product_name}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

export default ProductBoxList;
