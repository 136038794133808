import { combineReducers } from 'redux';
import authReducer, { AuthState } from './modules/auth.reducer';
import miscReducer, { MiscState } from './modules/misc.reducer';

export interface RootState {
	auth: AuthState;
	misc: MiscState;
}

export default combineReducers<RootState>({
	auth: authReducer,
	misc: miscReducer,
});
