import React from "react";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";

const AddTemplateForm = () => {
  const templateForm = useFormik({
    initialValues: {
      name: "",
      details: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required("template name is required"),
      details: Yup.string().trim().required("template details required"),
    }),
    onSubmit(values, formikHelpers) {
      console.log(values);
    },
  });

  return (
    <form className="w-full">
      <section>
        <h3 className="text-[color:var(--orange)] text-2xl font-bold">
          New Template
        </h3>
      </section>
      <section className="flex flex-col gap-6 mt-8">
        <div className="flex flex-col gap-2">
          <label
            htmlFor="template-name"
            className="text-gray-400 font-bold text-base"
          >
            Template name
          </label>
          <input
            id="template-name"
            placeholder="name"
            {...templateForm.getFieldProps("name")}
            className={`bg-[color:var(--dark-charcoal)] ring-1 focus:ring-white text-white ${
              templateForm.touched.name &&
              templateForm.errors.name &&
              "ring-red-500 focus:ring-red-500 "
            }`}
            style={{
              paddingInline: "20px",
              paddingBlock: "10px",
              borderRadius: "10px",
            }}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="template-details"
            className="text-gray-400 font-bold text-base"
          >
            Template details
          </label>
          <textarea
            placeholder="details"
            id="template-details"
            {...templateForm.getFieldProps("details")}
            className="bg-[color:var(--dark-charcoal)] text-white"
            rows={6}
            style={{
              paddingInline: "20px",
              paddingBlock: "10px",
              borderRadius: "10px",
            }}
            // disabled={isOld || loading}
            //   value={template}
            //   onChange={(e) => {
            //     setTemplate(e.target.value);
            //   }}
          />
        </div>

        <Button
          disableElevation={true}
          variant="contained"
          //   onClick={() =>
          //     templateName.length > 0 &&
          //     template.length > 0 &&
          //     addTemplate()
          //   }
          style={{
            paddingBlock: "1rem",
            color: "#fff",
            marginTop: "32px",
            borderRadius: "8px",
          }}
        >
          + ADD
        </Button>
      </section>
    </form>
  );
};

export default AddTemplateForm;
