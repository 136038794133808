import { Customer } from "../../@types";
import api from "../api";

export class CustomersRequest {
  /**
   * fetches all customers
   *
   * @returns {Promise<Customer[]>} - resolved or rejected promise object
   */
  static FetchAll(): Promise<Customer[]> {
    return new Promise((resolve, reject) => {
      api
        .get<Customer[]>("/customers", {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }
  /**
   * fetches one customer
   *
   * @param customerId - customer customerId
   * @returns {Promise<Customer>} - resolved or rejected promise object
   */
  static FetchOne(id: string): Promise<any> {
    return new Promise((resolve, reject) => {});
  }
  /**
   * fetches abandoned customers
   *
   * @returns {Promise<Customer>} - resolved or rejected promise object
   */
  static FetchAbandonedCustomers(): Promise<any> {
    return new Promise((resolve, reject) => {
      api
        .get<Customer[]>("/abandoned", {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }
}
