import { useRef, useState } from "react";
import React from "react";
import QRCode from "react-qr-code";
import { toPng } from "html-to-image";
import { toast } from "react-toastify";
import logo from "../assets/images/qr-logo.png";
import CustomSelect from "./shared/CustomSelect";

const BOXES: {
  product_code: string;
  product_name: string;
}[] = [
  { product_code: "MCL1", product_name: "Computer Science" },
  { product_code: "MCL2", product_name: "Neuroscience" },
  { product_code: "MCL3", product_name: "Dinosaurs" },
  { product_code: "MCL4", product_name: "Good habits" },
  { product_code: "MCL5", product_name: "Words" },
  { product_code: "MCL6", product_name: "Planets" },
  { product_code: "MCL7", product_name: "Shapes" },
  { product_code: "MCL8", product_name: "Insects and Bugs" },
  { product_code: "SKU2", product_name: "Mochi App Robotics Kit" },
  { product_code: "SKU3", product_name: "Learn Colors with Mochi" },
  { product_code: "SKU4", product_name: "Mochi Learns Numbers" },
  { product_code: "SKU5", product_name: "Learn Letters with Mochi" },
  { product_code: "SKU6", product_name: "Learn Shapes with Mochi" },
  { product_code: "SKU7", product_name: "Learn Animals with Mochi" },
  { product_code: "SKU8", product_name: "Mochi Learns Words" },
  { product_code: "SKU9", product_name: "Learn Fruits and Vegetables" },
  { product_code: "SKU10", product_name: "Learn Earth With Mochi" },
  { product_code: "SKU11", product_name: "Learn Time With Mochi" },
  { product_code: "SKU12", product_name: "Learn Biology With Mochi" },
  { product_code: "SKU13", product_name: "Learn Good Habits With Mochi" },
];

export default function ProductQR() {
  const ref = useRef<HTMLDivElement>(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [selectedCode, setSelectedCode] = useState<{
    product_code: string;
    product_name: string;
  }>();

  const renderBox = (code: string) => {
    switch (code) {
      case "MCL1":
        return "Computer Science";
      case "MCL2":
        return "Neuroscience";
      case "MCL3":
        return "Dinosaurs";
      case "MCL4":
        return "Good habits";
      case "MCL5":
        return "Words";
      case "MCL6":
        return "Planets";
      case "MCL7":
        return "Shapes";
      case "MCL8":
        return "Insects and Bugs";
      case "SKU1":
        return "Mochi Screenless Coding Robotics Kit";
      case "SKU2":
        return "Mochi App Robotics Kit";
      case "SKU3":
        return "Learn Colors with Mochi";
      case "SKU4":
        return "Mochi Learns Numbers";
      case "SKU5":
        return "Learn Letters with Mochi";
      case "SKU6":
        return "Learn Shapes with Mochi";
      case "SKU7":
        return "Learn Animals with Mochi";
      case "SKU8":
        return "Mochi Learns Words";
      case "SKU9":
        return "Learn Fruits and Vegetables";
      case "SKU10":
        return "Learn Earth With Mochi";
      case "SKU11":
        return "Learn Time With Mochi";
      case "SKU12":
        return "Learn Biology With Mochi";
      case "SKU13":
        return "Learn Good Habits With Mochi";
    }
  };

  function hashCode(s: string) {
    return s.split("").reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  }

  function saveQR(productCode: string) {
    toast(`Downloading ${productCode} QR Code`);
    if (ref.current === null) {
      return;
    }

    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `LWM-${productCode}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log({ err });
      });
  }

  return (
    <div className="pb-20">
      <div className="text-2xl mt-10 mb-10 font-bold">Product QR</div>
      <div className="text-xl mt-20 mb-5 font-bold">
        Select a product to view its QR code
      </div>
      <CustomSelect
        wrapperClass="w-[400px]"
        togglerClass="bg-[rgba(255,255,255,0.07)] text-white"
        options={BOXES.map(
          (box: { product_code: string; product_name: string }) => ({
            name: (box.product_code === "SKU2" ? "SKU1.3" : box.product_code)
              .concat(" - ")
              .concat(box.product_name),
            label: box.product_code,
          })
        )}
        onChange={(value) => {
          // setSelectedCode(value.name)
          const code = value.label;
          setSelectedCode({
            product_code: code,
            product_name:
              BOXES.find((item) => item.product_code === code)?.product_name ??
              "",
          });
          // console.log(code);
        }}
      />
      <div className="mt-20">
        {selectedCode && (
          <div
            // onClick={() => onCustomerSelected(influencer.id)}
            onClick={
              () => {}
              //   onUserSelect(influencer.id, influencer.email)
            }
            className="flex flex-col justify-center items-center"
          >
            <div className="flex justify-center items-center mb-5 w-[450px] ">
              <div className="flex gap-5">
                <input
                  onChange={(e) => {
                    setIsDarkMode(e.target.checked);
                  }}
                  type="checkbox"
                  className=""
                />
                <div className="text-lg">Use Dark Version</div>
              </div>
            </div>

            <div className={`${isDarkMode ? "" : ""}`} ref={ref}>
              <div
                style={{
                  // width: "min-content",
                  marginBlock: 5,
                }}
                className="cursor-pointer transition-all translate-x-[25%]"
              >
                <div
                  ref={ref}
                  className="w-fit h-[450px] flex justify-center items-center"
                >
                  <QRCode
                    className=""
                    fgColor={isDarkMode ? "black" : "white"}
                    bgColor="#ffffff00"
                    size={450}
                    value={`https://learnwithmochi.com/app/LWM-${hashCode(
                      selectedCode.product_code
                    )}`}
                  />

                  <div className="w-[450px] h-[450px] -translate-x-full flex items-center justify-center">
                    <img className="h-20 w-20" src={logo} alt="" />
                  </div>
                </div>
              </div>
              <div className="flex flex-row justify-center items-center">
                <div
                  className={`w-[450px] text-center text-5xl px-5 py-2 rounded-xl border-2  mt-5  font-bold ${
                    isDarkMode
                      ? "text-black border-black"
                      : "text-white border-white"
                  }`}
                >
                  {hashCode(selectedCode.product_code)}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center flex-col mt-4">
              <div className="text-xl">
                {selectedCode.product_code === "SKU2"
                  ? "SKU1.3"
                  : selectedCode.product_code}
              </div>
              <div className="text-xl text-center text-[#FACC14]">
                {renderBox(selectedCode.product_code)}
              </div>
              <div>
                <div
                  onClick={() => {
                    saveQR(selectedCode.product_code);
                  }}
                  className="bg-black rounded-lg px-5 py-1 mt-4 cursor-pointer"
                >
                  Download QR
                </div>
              </div>
            </div>
          </div>
        )}

        {!selectedCode && (
          <div className="text-xl text-center flex items-center justify-center flex-col">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-10 h-10"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
              />
            </svg>
            No product selected
          </div>
        )}
      </div>
    </div>
  );
}
