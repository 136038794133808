/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { KeyboardBackspace } from "@material-ui/icons";
import SideBar from "../components/sidebar";
import { useParams, useNavigate } from "react-router-dom";
import { Influencer } from "../@types";
import api from "../network/api";
import { toast } from "react-toastify";
import Correspondence from "../components/dashboard/Correspondence";
import InfluencerCheckout from "../components/dashboard/Checkout";
import CircularLoading from "../components/Animations/Loading";
import Popup from "reactjs-popup";

const steps = [
  "1st email sent",
  "2nd email sent",
  "Correspondence",
  "Order form completed",
  "Tracking link created",
  "In route",
  "Delivered",
  "Posted",
  
];

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    alignItems: "center",
    padding: ".5rem",
    color: "#fff",
    flexDirection: "column",
  },
  loading: {
    height: "40vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: ".5rem",
    paddingTop: "1rem",
    color: "#fff",
  },
  backIcon: {
    backgroundColor: "#ffcc00",
    fontSize: "2rem",
    borderRadius: "50%",
    color: "#000",
    marginRight: "2rem",
    padding: "5px",
    cursor: "pointer",
  },
  igName: {
    cursor: "pointer",
    "&:hover": {
      color: "#20bae0",
    },
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  tab: {
    display: "flex",
    borderRadius: "10px",
    marginLeft: "3rem",
  },
  correspondence: {
    fontWeight: "bold",
    textTransform: "uppercase",
    padding: ".7rem",
    cursor: "pointer",
    transition: "all 0.2 ease",
    backgroundColor: "#C3D4DC",
    color: "#000",
    paddingRight: "30px",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  correspondenceSelected: {
    fontWeight: "bold",
    textTransform: "uppercase",
    cursor: "pointer",
    zIndex: 10,
    transition: "all 0.2 ease",
    padding: ".7rem",
    backgroundColor: "#ffcc00",
    color: "#000",
    borderRadius: "10px",
  },
  orderDetails: {
    fontWeight: "bold",
    textTransform: "uppercase",
    cursor: "pointer",
    padding: ".7rem",
    backgroundColor: "#C3D4DC",
    transition: "all 0.2 ease",
    paddingLeft: "30px",
    color: "#000",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
  },
  orderDetailsSelected: {
    fontWeight: "bold",
    textTransform: "uppercase",
    cursor: "pointer",
    transition: "all 0.2 ease",
    padding: ".7rem",
    backgroundColor: "#ffcc00",
    color: "#000",
    zIndex: 10,
    borderRadius: "10px",
  },
  googleButton: {
    marginRight: "3rem",
    borderRadius: "20px",
  },
  googleDiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

const IgDetails: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState<Influencer>();
  const params = useParams<{ email: string }>();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [currentStep, setCurrentStep] = React.useState(-1);
  const [conversations, setConversations] = useState<any>([]);
  const [loadingConversations, setLoadingConversations] = useState(true);
  const [userData, setUserData] = useState<any>({});

  const getUserData = async (email: string) => {
    try {
      let data = await api.get(`/influencer/${email}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      });
      setUserData(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setTimeline = async (step: number) => {
    try {
      const response = await api.post(
        `/timeline/${user?.email}`,
        {
          timeline: step.toString(),
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Timeline updated");
        setCurrentStep(step);
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  const fetchConversations = async (
    email: string,
    timeline: string,
    isOrdered: boolean
  ) => {
    try {
      const { data } = await api.get(`/messages/${email}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      });

      setLoadingConversations(false);

      setConversations(data);

      if (data.length === 1) {
        setCurrentStep(0);
      }
      if (data.length > 1) {
        setCurrentStep(1);
      }

      for (var i = 0; i < data.length; i++) {
        if (data[i].email === `${email}`) {
          setCurrentStep(2);
        }
      }

      if (isOrdered) {
        setCurrentStep(3);
      }

      if (timeline !== "-1") {
        setCurrentStep(parseInt(timeline));
      }
    } catch (error) {
      setLoadingConversations(false);
      toast.error("could not load conversations: " + error);
    }
  };

  const fetchDetails = async () => {
    setLoading(true);
    try {
      try {
        const response = await api.get(`/influencer/${params.email}`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        });
        console.log(response);
        setUser(response.data);
        fetchConversations(
          response.data.email,
          response.data.timeline,
          response.data.isOrdered
        );
        getUserData(response.data.email);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    } catch (error) {
      toast.error("Unable to fetch details of user");
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchDetails();
  }, [params.email]);

  // const gotoProfile = () => {
  //   window.open(user?.account?.link, "_blank");
  // };

  return (
    <div className="container">
      <aside className="sidebar">
        <SideBar />
      </aside>
      <main className="main">
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              width: "80vw",
              flexDirection: "column",
              opacity: loading ? "1" : "0",
              transition: "all 0.3s ease",
              transform: loading ? "translateY(30vh)" : "translateY(20vh)",
            }}
          >
            <CircularLoading />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              opacity: loading ? "0" : "1",
              transition: "all 0.3s ease",
              transform: loading ? "translateY(0px)" : "translateY(0px)",
            }}
          >
            <div className={classes.header}>
              <KeyboardBackspace
                className={classes.backIcon}
                onClick={() => navigate(-1)}
              />
              <h2
                style={{ fontSize: "1.5rem" }}
              >{`${user?.first_name} ${user?.last_name}`}</h2>

              <div className={classes.tab}>
                <div
                  onClick={() => setSelectedTab(0)}
                  className={
                    selectedTab === 0
                      ? classes.correspondenceSelected
                      : classes.correspondence
                  }
                >
                  Correspondence
                </div>
                <div
                  style={{ transform: "translate(-20px)" }}
                  onClick={() => setSelectedTab(1)}
                  className={
                    selectedTab === 1
                      ? classes.orderDetailsSelected
                      : classes.orderDetails
                  }
                >
                  Order Details
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "50px",
                marginTop: "30px",
                padding: ".5rem",
                color: "#fff",
              }}
            >
              {steps.map((step, index) => (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        transform:
                          index === 5 || index === 7
                            ? "translate(-1rem)"
                            : index === 6
                            ? "translate(-1.5rem)"
                            : "translate(-2.5rem)",
                      }}
                    >
                      {step}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        marginTop: "10px",
                      }}
                    >
                      <Popup
                        trigger={
                          <div
                            // onClick={() => setTimeline(index)}
                            style={{
                              height:
                                index === 0 || index + 1 === steps.length
                                  ? "22px"
                                  : "14px",
                              width:
                                index === 0 || index + 1 === steps.length
                                  ? "22px"
                                  : "14px",
                              zIndex: 10,
                              transform: "translate(-5px)",
                              backgroundColor:
                                currentStep >= index ? " #ffcc00" : "grey",
                              borderRadius: "50%",
                              cursor: "pointer",
                            }}
                          ></div>
                        }
                        on={["hover", "focus"]}
                        position={"top center"}
                      >
                        Switch to {steps[index]}
                      </Popup>

                      {index !== steps.length - 1 && (
                        <div
                          style={{
                            width: "150px",
                            height: "3px",
                            transform: "scaleX(1.1)",
                            backgroundColor:
                              currentStep > index ? " #ffcc00" : "white",
                          }}
                        ></div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {selectedTab === 0 ? (
              <Correspondence
                loadingConversations={loadingConversations}
                initconversations={conversations}
                user={user}
              />
            ) : (
              <InfluencerCheckout userData={userData} user={user} />
            )}
          </div>
        )}
      </main>
    </div>
  );
};

export default IgDetails;
