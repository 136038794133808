import { AnyAction } from "redux";

export interface MiscState {
  activePage: number;
  searchInput: string;
  activeSidebar: string;
  selectedCustomerTab: string;
  newTemplateModalIsOpen: boolean;
}

export const MISC_ACTIONS = {
  SET_ACTIVE_PAGE: "SET_ACTIVE_PAGE",
  SET_SEARCH_INPUT: "SET_SEARCH_INPUT",
  SET_ACTIVE_SIDE_BAR: "SET_ACTIVE_SIDE_BAR",
  OPEN_NEW_TEMPLATE_MODAL: "OPEN_NEW_TEMPLATE_MODAL",
  SET_SELECTED_CUSTOMER_TAB: "SET_SELECTED_CUSTOMER_TAB",
};

export const initialState: MiscState = {
  activePage: 1,
  searchInput: "",
  activeSidebar: "customers",
  selectedCustomerTab: "live",
  newTemplateModalIsOpen: false,
};

/**
 *
 * @param {Boolean} open - modal state flag
 * @returns {Object.<string, string>} - action type
 */
export const setActiveSidebar = (activeSidebar: string) => {
  return {
    type: MISC_ACTIONS.SET_ACTIVE_SIDE_BAR,
    payload: activeSidebar,
  };
};
/**
 * sets search key
 *
 * @param {string} search - search filter
 * @returns {Object.<string, string>} - action type
 */
export const setSearchInput = (search: string) => {
  return {
    type: MISC_ACTIONS.SET_SEARCH_INPUT,
    payload: search,
  };
};
/**
 * sets an active page number
 *
 * @param {number} activePage - page to set
 * @returns {Object.<string, string>} - action type
 */
export const setActivePageNumber = (activePage: number) => {
  return {
    type: MISC_ACTIONS.SET_ACTIVE_PAGE,
    payload: activePage,
  };
};
/**
 * sets selected customer tab
 *
 * @param {string} selectedTab - tab to select
 * @returns {Object.<string, string>} - action type
 */
export const setSelectedCustomerTab = (selectedTab: string) => {
  return {
    type: MISC_ACTIONS.SET_SELECTED_CUSTOMER_TAB,
    payload: selectedTab,
  };
};
/**
 * opens and closes 'new template' modal
 *
 * @param {Boolean} open - modal state flag
 * @returns {Object.<string, string>} - action type
 */
export const openNewTemplateModal = (open = true) => {
  return {
    type: MISC_ACTIONS.OPEN_NEW_TEMPLATE_MODAL,
    payload: open,
  };
};

export default function miscReducer(
  state = initialState,
  action: AnyAction
): MiscState {
  switch (action.type) {
    case MISC_ACTIONS.SET_ACTIVE_SIDE_BAR:
      return { ...state, activeSidebar: action.payload, activePage: 1 };

    case MISC_ACTIONS.SET_ACTIVE_PAGE:
      return { ...state, activePage: action.payload };

    case MISC_ACTIONS.SET_SEARCH_INPUT:
      return { ...state, searchInput: action.payload };

    case MISC_ACTIONS.SET_SELECTED_CUSTOMER_TAB:
      return { ...state, selectedCustomerTab: action.payload };

    case MISC_ACTIONS.OPEN_NEW_TEMPLATE_MODAL:
      return { ...state, newTemplateModalIsOpen: action.payload };

    default:
      return state;
  }
}
