import { Dialog, Transition } from "@headlessui/react";
import { Typography } from "@material-ui/core";
import { format } from "date-fns";
import { Fragment, useState } from "react";
import { Shipment } from "../@types";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomSelect from "./shared/CustomSelect";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import api from "../network/api";
import { toast } from "react-toastify";

type props = {
  item: Shipment;
};

const BOXES: {
  product_code: string;
  product_name: string;
}[] = [
  { product_code: "MCL 1", product_name: "Computer Science" },
  { product_code: "MCL 2", product_name: "Neuroscience" },
  { product_code: "MCL 3", product_name: "Dinosaurs" },
  { product_code: "MCL 4", product_name: "Good habits" },
  { product_code: "MCL 5", product_name: "Words" },
  { product_code: "MCL 6", product_name: "Planets" },
  { product_code: "MCL 7", product_name: "Shapes" },
  { product_code: "MCL 8", product_name: "Insects and Bugs" },
  {
    product_code: "SKU1",
    product_name: "Mochi Screenless Coding Robotics Kit",
  },
  { product_code: "SKU2", product_name: "Mochi App Robotics Kit" },
  { product_code: "SKU3", product_name: "Learn Colors with Mochi" },
  { product_code: "SKU4", product_name: "Mochi Learns Numbers" },
  { product_code: "SKU5", product_name: "Learn Letters with Mochi" },
  { product_code: "SKU6", product_name: "Learn Shapes with Mochi" },
  { product_code: "SKU7", product_name: "Learn Animals with Mochi" },
  { product_code: "SKU8", product_name: "Mochi Learns Words" },
  { product_code: "SKU9", product_name: "Learn Fruits and Vegetables" },
  { product_code: "SKU10", product_name: "Learn Earth With Mochi" },
  { product_code: "SKU11", product_name: "Learn Time With Mochi" },
  { product_code: "SKU12", product_name: "Learn Biology With Mochi" },
  { product_code: "SKU13", product_name: "Learn Good Habits With Mochi" },
];

export default function ShipmentItem({ item }: props) {
  let [isOpen, setIsOpen] = useState(false);
  const [showPicker, setShowPicker] = useState(false);

  const shipmentUpdatedDetailsForm = useFormik({
    initialValues: {
      name: item.name,
      box_type: item.box_type,
      date_shipped: item.date_shipped,
      tracking_link: item.tracking_link,
    },
    validationSchema: Yup.object({
      box_type: Yup.string().required("select box type"),
      date_shipped: Yup.string().required("when was it shipped"),
      tracking_link: Yup.string().required("enter tracking link"),
    }),
    onSubmit(values, formikHelpers) {
      item.box_type = values.box_type;
      item.date_shipped = values.date_shipped;
      item.tracking_link = values.tracking_link;

      try {
        api.post("/customers/shipment", {
          tracking_link: values.tracking_link,
          box_type: values.box_type,
          date_shipped: values.date_shipped,
          email: item.email,
          id: item.id,
        });
        setIsOpen(false);
        toast.success("Shipment Updated");
      } catch (error) {
        toast.error("An error occured");
      }

      formikHelpers.setSubmitting(false);
    },
  });

  function openModal(open = true) {
    setIsOpen(open);
  }

  const renderBox = (code: string) => {
    switch (code) {
      case "MCL 1":
        return "Computer Science";
      case "MCL 2":
        return "Neuroscience";
      case "MCL 3":
        return "Dinosaurs";
      case "MCL 4":
        return "Good habits";
      case "MCL 5":
        return "Words";
      case "MCL 6":
        return "Planets";
      case "MCL 7":
        return "Shapes";
      case "MCL 8":
        return "Insects and Bugs";
      case "SKU1":
        return "Mochi Screenless Coding Robotics Kit";
      case "SKU2":
        return "Mochi App Robotics Kit";
      case "SKU3":
        return "Learn Colors with Mochi";
      case "SKU4":
        return "Mochi Learns Numbers";
      case "SKU5":
        return "Learn Letters with Mochi";
      case "SKU6":
        return "Learn Shapes with Mochi";
      case "SKU7":
        return "Learn Animals with Mochi";
      case "SKU8":
        return "Mochi Learns Words";
      case "SKU9":
        return "Learn Fruits and Vegetables";
      case "SKU10":
        return "Learn Earth With Mochi";
      case "SKU11":
        return "Learn Time With Mochi";
      case "SKU12":
        return "Learn Biology With Mochi";
      case "SKU13":
        return "Learn Good Habits With Mochi";
    }
  };

  const renderBoxCover = (code: string) => {
    switch (code) {
      case "MCL 1":
        return "../../assets/images/nuber_systems.png";
      case "MCL 2":
        return "../../assets/images/Neuroscience.png";
      case "MCL 3":
        return "../../assets/images/Dinosaurs.png";
      case "MCL 4":
        return "../../assets/images/Good Habits.png";
      case "MCL 5":
        return "../../assets/images/Words.png";
      case "MCL 6":
        return "../../assets/images/Planets.png";
      case "MCL 7":
        return "../../assets/images/Shapes.png";
      case "MCL 8":
        return "../../assets/images/insetsandbugs.png";
      case "SKU1":
        return "../../assets/images/SKU1.webp";
      case "SKU2":
        return "../../assets/images/app_robotics_kit.png";
      case "SKU4":
        return "../../assets/images/SKU4.webp";
      case "SKU3":
        return "../../assets/images/SKU3.webp";
      case "SKU5":
        return "../../assets/images/SKU5.webp";
      case "SKU6":
        return "../../assets/images/SKU6.webp";
      case "SKU7":
        return "../../assets/images/SKU7.webp";
      case "SKU8":
        return "../../assets/images/SKU8.webp";
      case "SKU9":
        return "../../assets/images/SKU9.webp";
      case "SKU10":
        return "../../assets/images/SKU10.webp";
      case "SKU11":
        return "../../assets/images/SKU11.webp";
      case "SKU12":
        return "../../assets/images/SKU12.webp";
      case "SKU13":
        return "../../assets/images/SKU13.webp";
      default:
        return "../../assets/images/SKU3.webp";
    }
  };

  return (
    <>
      <div
        onClick={() => {
          openModal();
        }}
        className="flex items-center justify-center cursor-pointer"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "center",
            marginInline: "20px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginBottom: "20px",
              fontWeight: "bold",
              fontSize: "1.1rem",
            }}
          >
            {format(new Date(item.date_shipped), "LLLL")}
          </div>
          <img
            alt=""
            height="80px"
            width="80px"
            style={{ borderRadius: 5 }}
            src={renderBoxCover(item.box_type)}
          />
          <div
            style={{
              marginTop: "10px",
              fontWeight: "bold",
            }}
          >
            {renderBox(item.box_type)}
          </div>
          <div style={{ marginTop: "10px" }}>
            {format(
              new Date(shipmentUpdatedDetailsForm.values.date_shipped),
              "dd LLLL yyyy"
            )}
          </div>
          <Typography gutterBottom variant="body1">
            <div
              onClick={() => window.open(`${item.tracking_link}`, "_blank")}
              style={{
                // display: "flex",
                // flexDirection: "row",
                // alignItems: "center",
                cursor: "pointer",
                color: "#5469D4",
              }}
            >
              <div>{item.tracking_link}</div>
            </div>
          </Typography>
        </div>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[9999]"
          onClose={() => openModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-70 backdrop-blur" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full flex justify-center items-center flex-col max-w-3xl px-10 pt-8 pb-16 transform  rounded-xl bg-[color:var(--charcoal)] p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-bold flex items-center flex-col leading-6 text-[color:var(--orange)] mb-8"
                  >
                    {renderBox(shipmentUpdatedDetailsForm.values.box_type)}
                  </Dialog.Title>
                  <div className="mt-2 flex gap-12">
                    <div className="flex-1 transition-all duration-150">
                      <img
                        alt=""
                        // height="150px"
                        // width="150px"
                        className="w-full h-auto"
                        style={{ borderRadius: 5 }}
                        src={renderBoxCover(
                          shipmentUpdatedDetailsForm.values.box_type
                        )}
                      />
                    </div>
                    <div className="flex-1">
                      {/* <ProductBoxList
                        onItemSelected={(box: Box) => {
                          setBoxType(box);
                        }}
                      /> */}
                      <CustomSelect
                        defaultSelectedIndex={BOXES.findIndex(
                          (box) => box.product_code === item.box_type
                        )}
                        wrapperClass="!w-full"
                        togglerClass="bg-[rgba(255,255,255,0.07)] text-white"
                        options={BOXES.map(
                          (box: {
                            product_code: string;
                            product_name: string;
                          }) => ({
                            name: (box.product_code === "SKU2" ? "SKU1.3" : box.product_code)
                              .concat(" - ")
                              .concat(box.product_name),
                            label: box.product_code
                              .concat(" - ")
                              .concat(box.product_name),
                          })
                        )}
                        onChange={(value) => {
                          let [product_code]: string[] = value.name
                            .toString()
                            .split(" - ");

                          // setBoxType({ product_code, product_name });
                          shipmentUpdatedDetailsForm.setFieldValue(
                            "box_type",
                            product_code
                          );
                        }}
                      />
                      <div className="mt-5 text-base text-gray-200 font-normal">
                        <label
                          htmlFor="tracking_link"
                          className="font-semibold"
                        >
                          Tracking link
                        </label>
                        <input
                          id="tracking_link"
                          type="text"
                          {...shipmentUpdatedDetailsForm.getFieldProps(
                            "tracking_link"
                          )}
                          className="block bg-transparent border-b w-full text-gray-400 border-gray-400 py-1"
                        />
                      </div>
                      <div className="mt-5 text-base text-gray-200 font-normal relative">
                        <label htmlFor="date_shipped" className="font-semibold">
                          Date
                        </label>
                        <input
                          type="text"
                          value={new Date(
                            shipmentUpdatedDetailsForm.values.date_shipped
                          ).toDateString()}
                          onClick={() => setShowPicker(!showPicker)}
                          className="block bg-transparent border-b w-full text-gray-400 border-gray-400 py-1"
                        />
                        <Transition
                          as={Fragment}
                          show={showPicker}
                          enter="transform transition-all duration-[300ms]"
                          enterFrom="opacity-0 -translate-y-2"
                          enterTo="opacity-100 translate-y-0 "
                          leave="transform duration-200 translate-y-0 transition ease-in-out"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0 scale-95 -translate-y-2"
                        >
                          <div className="absolute top-[length:calc(100%+0.5rem)] rounded overflow-hidden bg-[color:var(--charcoal)] shadow-md shadow-[rgba(255,255,255,0.2)] ring-1 ring-gray-500">
                            <DayPicker
                              mode="single"
                              selected={
                                shipmentUpdatedDetailsForm.values.date_shipped
                                  ? new Date(
                                      shipmentUpdatedDetailsForm.values.date_shipped
                                    )
                                  : new Date()
                              }
                              modifiersClassNames={{
                                selected:
                                  "!bg-[rgba(255,255,255,0.09)] !text-[color:var(--orange)]",
                              }}
                              modifiersStyles={{
                                disabled: { fontSize: "75%" },
                              }}
                              onSelect={(date: Date) => {
                                shipmentUpdatedDetailsForm.setFieldValue(
                                  "date_shipped",
                                  date.toDateString()
                                );
                                setShowPicker(false);
                              }}
                            />
                          </div>
                        </Transition>
                      </div>
                      {/* <div className="mt-5 text-base text-gray-200 font-normal">
                        <label htmlFor="email" className="font-semibold">
                          Email
                        </label>
                        <input
                          id="email"
                          type="text"
                          {...shipmentUpdatedDetailsForm.getFieldProps("email")}
                          className="block bg-transparent border-b w-full border-gray-400 py-1 text-gray-400"
                        />
                      </div> */}
                      <div className="mt-7 flex gap-5 items-center">
                        <button
                          type="button"
                          className="inline-flex flex-1 justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium transition-all duration-150 text-gray-300 ring-1 ring-gray-500 hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={() => openModal(false)}
                        >
                          Close
                        </button>
                        <button
                          disabled={shipmentUpdatedDetailsForm.isSubmitting}
                          onClick={() =>
                            shipmentUpdatedDetailsForm.submitForm()
                          }
                          // type="button"
                          className="inline-flex disabled:cursor-not-allowed disabled:bg-green-300 flex-1 justify-center rounded-md border border-transparent hover:bg-green-500 transition-all duration-150 px-4 py-2 text-sm font-medium bg-green-600 text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
