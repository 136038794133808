import React from 'react';

interface TextFieldProps {
	id: string;
	placeholder: string;
	type: string;
	className?: string;
	value: string | number;
	error?: string;
	name: string;
	handleChange: (e: any) => void;
	handleBlur?: (e: any) => void;
	disabled?: boolean;
}

const MochiTextField: React.FC<TextFieldProps> = ({
	id,
	placeholder,
	type,
	className,
	value,
	name,
	error,
	handleBlur,
	handleChange,
	disabled,
}) => {
	return (
		<div className="text-field">
			<div className="space-between row">
				<input
					id={id}
					name={name}
					className={className ?? ''}
					placeholder={placeholder}
					type={type}
					value={value}
					onChange={handleChange}
					onBlur={handleBlur}
					disabled={disabled}
				/>
			</div>
			{error && <p className="error">{error}</p>}
		</div>
	);
};

export default MochiTextField;
