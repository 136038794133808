import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import React from "react";
import Popup from "reactjs-popup";
import * as Yup from "yup";

const InfluencersHeader = () => {
  const influencerForm = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      instagramHandle: "",
      facebookHandle: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().optional(),
      lastName: Yup.string().optional(),
      email: Yup.string().trim().required("email is required"),
      instagramHandle: Yup.string().optional(),
      facebookHandle: Yup.string().optional(),
    }),
    onSubmit(values, formikHelpers) {
      console.log(values);
    },
  });
  return (
    <header className="p-4 sticky top-0">
      <div className="flex items-center justify-between">
        <h2 className="pr-6 text-[#FFCC00] text-lg">Influencers</h2>
        <div
          style={
            {
              // pointerEvents: isAddInfluencerVisible ? "auto" : "none",
            }
          }
        >
          <Popup
            contentStyle={{
              transition: "ease 1s all",
              // pointerEvents: isAddInfluencerVisible ? "auto" : "none",
              backgroundColor: "#000",
              borderRadius: "10px",
              height: "600px",
              width: "600px",
              // opacity: isAddInfluencerVisible ? "1" : "0",
            }}
            overlayStyle={
              {
                // opacity: isAddInfluencerVisible ? "1" : "0",
              }
            }
            // onClose={() => setIsAddInfluencerVisible(false)}
            trigger={
              <div>
                <Button
                  disableElevation={true}
                  variant="contained"
                  // onClick={() => setIsAddInfluencerVisible(true)}
                  style={{
                    backgroundColor: "#ffc000",
                    color: "var(--darker-charcoal)",
                    // display: activeSidebar === "influencers" ? "block" : "none",
                    borderRadius: "8px",
                  }}
                >
                  + ADD
                </Button>
              </div>
            }
            modal
            nested
          >
            {(close: any) => (
              <div
                style={{
                  margin: "30px",
                  color: "white",
                  height: "70vh",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                }}
              >
                <div
                  style={{
                    // marginLeft: "98%",
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                    color: "black",
                    textAlign: "center",
                    borderRadius: "50%",
                    backgroundColor: "#ffcc00",
                    fontWeight: "bold",
                    fontSize: "2rem",
                  }}
                  onClick={close}
                >
                  &times;
                </div>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.7rem",
                  }}
                >
                  {" "}
                  Add new influencer
                </p>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <p style={{}}>Firstname (Optional)</p>
                    <input
                      placeholder="Firstname"
                      style={{
                        // display: showSubjectLine ? "flex" : "none",
                        paddingInline: "20px",
                        // width: "100%",
                        paddingBlock: "10px",
                        borderRadius: "10px",
                        backgroundColor: "white",
                        color: "#000",
                        fontWeight: "bold",
                      }}
                      // disabled={isOld || loading}
                      {...influencerForm.getFieldProps("firstName")}
                      // value={firstname}
                      // onChange={(e) => {
                      //   setFirstname(e.target.value);
                      // }}
                    />
                  </div>

                  <div style={{ marginLeft: "60px" }}>
                    <p style={{}}>Lastname (Optional)</p>
                    <input
                      placeholder="Lastname"
                      style={{
                        // display: showSubjectLine ? "flex" : "none",
                        paddingInline: "20px",
                        // width: "100%",
                        paddingBlock: "10px",
                        borderRadius: "10px",
                        backgroundColor: "white",
                        color: "#000",
                        fontWeight: "bold",
                      }}
                      // disabled={isOld || loading}
                      {...influencerForm.getFieldProps("lastName")}
                      // value={lastname}
                      // onChange={(e) => {
                      //   setLastname(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <div>
                  <p style={{}}>Email (Required)</p>
                  <input
                    placeholder="Email"
                    style={{
                      // display: showSubjectLine ? "flex" : "none",
                      paddingInline: "20px",
                      // width: "100%",
                      paddingBlock: "10px",
                      borderRadius: "10px",
                      backgroundColor: "white",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                    // disabled={isOld || loading}
                    {...influencerForm.getFieldProps("email")}
                    // value={email}
                    // onChange={(e) => {
                    //   setEmail(e.target.value);
                    // }}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    {" "}
                    <p style={{}}>Instagram handle (Optional)</p>
                    <input
                      placeholder="Instagram handle"
                      style={{
                        // display: showSubjectLine ? "flex" : "none",
                        paddingInline: "20px",
                        // width: "100%",
                        paddingBlock: "10px",
                        borderRadius: "10px",
                        backgroundColor: "white",
                        color: "#000",
                        fontWeight: "bold",
                      }}
                      // disabled={isOld || loading}
                      {...influencerForm.getFieldProps("instagramHandle")}
                      // value={instagram}
                      // onChange={(e) => {
                      //   setInstagram(e.target.value);
                      // }}
                    />
                  </div>
                  <div style={{ marginLeft: "60px" }}>
                    <p style={{}}>Facebook handle (Optional)</p>
                    <input
                      placeholder="Facebook handle"
                      style={{
                        // display: showSubjectLine ? "flex" : "none",
                        paddingInline: "20px",
                        // width: "100%",
                        paddingBlock: "10px",
                        borderRadius: "10px",
                        backgroundColor: "white",
                        color: "#000",
                        fontWeight: "bold",
                      }}
                      // disabled={isOld || loading}
                      {...influencerForm.getFieldProps("facebookHandle")}
                      // value={facebook}
                      // onChange={(e) => {
                      //   setFacebook(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <Button
                  disableElevation={true}
                  variant="contained"
                  // onClick={() => email.length > 0 && addInfluencer()}
                  style={{
                    backgroundColor:
                      influencerForm.values.email.length > 0
                        ? "#ffc000"
                        : "gray",
                    color: "#fff",
                    marginTop: "40px",
                    borderRadius: "8px",
                  }}
                >
                  + ADD
                </Button>
              </div>
            )}
          </Popup>
        </div>
      </div>
    </header>
  );
};

export default InfluencersHeader;
