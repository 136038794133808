import React, { ChangeEvent } from "react";
import Close from "@material-ui/icons/Close";

interface Props {
  EndIcon?: any;
  width?: number;
  isStatic?: boolean;
  searchInput: string;
  placeholder?: string;
  wrapperClass?: string;
  setSearchInput: (search: string) => void;
}

const SearchBar: React.FC<Props> = ({
  width,
  EndIcon,
  isStatic,
  searchInput,
  placeholder,
  setSearchInput,
  wrapperClass = "",
}) => {
  const [inputWidth, setInputWidth] = React.useState(width || 300);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const clearSearch = () => {
    setSearchInput("");
  };

  const onInputFocus = (focus: boolean) => {
    if (isStatic) return;
    if (focus) setInputWidth(350);
    else setInputWidth(300);
  };

  return (
    <div
      className={`bg-white rounded-md  transition-all duration-200 ${wrapperClass}`}
      style={{ width: inputWidth }}
    >
      <div className="space-between row padding row-input">
        <input
          placeholder={placeholder || "Search"}
          value={searchInput}
          type="text"
          className="text-[1.1rem]  p-2 text-[color:var(--dark-charcoal)]"
          onChange={handleChange}
          onFocus={() => onInputFocus(true)}
          onBlur={() => onInputFocus(false)}
        />
        {!EndIcon ? (
          searchInput ? (
            <Close
              className="cursor-pointer mt-[0.7rem] transform -translate-y-[0.3rem]"
              onClick={clearSearch}
            />
          ) : (
            <img src="/assets/images/searchIcon.svg" alt="search" />
          )
        ) : (
          <EndIcon />
        )}
      </div>
    </div>
  );
};

export default SearchBar;
